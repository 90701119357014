import Vue from 'vue'
import VueRouter from 'vue-router'


import test from '../pages/sharedFolder/test';
import ChartJS from '../pages/sharedFolder/chartJS.vue';
import googlemaps from '../pages/sharedFolder/GoogleMaps.vue';
import certificate from '../pages/sharedFolder/certificate';
import Register from '../pages/sharedFolder/auth/Register';
import Login from '../pages/sharedFolder/auth/Login.vue';

import Dashboard from '../pages/sharedFolder/Dashboard.vue';
import DashboardAdministrationOfVaccinations from '../pages/administrationOfVaccinationsFolder/dashboard/dashboard.vue';
import DashboardVaccinationCenters from '../pages/vaccinationCentersFolder/dashboard/dashboard.vue';
import DashboardMedicalSupplies from '../pages/medicalSuppliesFolder/dashboard/dashboard.vue';
import DashboardMunicipalities from '../pages/municipalitiesFolder/dashboard/dashboard.vue';



import profile from '../pages/sharedFolder/profile/profile.vue';
import EditProfile from '../pages/sharedFolder/profile/edit';

import NotFound from '../pages/sharedFolder/404';

import Administrators from '../pages/administrationOfVaccinationsFolder/administrators/index';
import AddAdministrators from '../pages/administrationOfVaccinationsFolder/administrators/add';
import EditAdministrators from '../pages/administrationOfVaccinationsFolder/administrators/edit';

import AdministratorsByMunicipal from '../pages/municipalitiesFolder/administrators/index.vue';

import Nationalities from '../pages/administrationOfVaccinationsFolder/nationalities/index.vue';
import AddNationality from '../pages/administrationOfVaccinationsFolder/nationalities/add';
import EditNationality from '../pages/administrationOfVaccinationsFolder/nationalities/edit';

import MedicalSupplies from '../pages/administrationOfVaccinationsFolder/medicalSupplies/index.vue';
import AddMedicalSupply from '../pages/administrationOfVaccinationsFolder/medicalSupplies/add';
import EditMedicalSupply from '../pages/administrationOfVaccinationsFolder/medicalSupplies/edit';

import MedicalSuppliessOrder from '../pages/medicalSuppliesFolder/medicalSupplyOrders/index.vue';
import AddMedicalSupplyOrder from '../pages/medicalSuppliesFolder/medicalSupplyOrders/add';
import EditMedicalSupplyOrder from '../pages/medicalSuppliesFolder/medicalSupplyOrders/edit';





import VaccinesMedicalsuppliesStorageByAdmin from '../pages/administrationOfVaccinationsFolder/medicalSupplies/storage.vue';


import VaccinesMedicalsuppliesStorage from '../pages/medicalSuppliesFolder/medicalSuppliesStorage/index.vue';
import AddVaccineToMedicalSupplyStorage from '../pages/medicalSuppliesFolder/medicalSuppliesStorage/add';

import DeliveryVaccinesMedicalSuppliesOrder from '../pages/medicalSuppliesFolder/medicalSupplyOrders/delivery.vue';


import Municipals from '../pages/administrationOfVaccinationsFolder/municipals/index.vue';
import AddMunicipal from '../pages/administrationOfVaccinationsFolder/municipals/add';
import EditMunicipal from '../pages/administrationOfVaccinationsFolder/municipals/edit';
import VaccinesMunicipalsStorage from '../pages/municipalitiesFolder/municipalsStorage/index.vue';

import VaccinesMunicipalsStorageByAdmin from '../pages/administrationOfVaccinationsFolder/municipals/storage.vue';


import VaccinesMunicipalsOrder from '../pages/municipalitiesFolder/municipalOrders/index.vue';
import AddVaccineToMunicipalOrder from '../pages/municipalitiesFolder/municipalOrders/add';
import EditVaccineToMunicipalOrder from '../pages/municipalitiesFolder/municipalOrders/edit';

import DeliveryVaccinesMunicipalsOrder from '../pages/municipalitiesFolder/municipalOrders/delivery.vue';
import MunicipalsOrderReadyToDelivery from '../pages/municipalitiesFolder/municipalOrders/readyToDelivery.vue';

import AddAdministratorsFromMunicipals from '../pages/municipalitiesFolder/administrators/add.vue';





import VaccinationCenters from '../pages/administrationOfVaccinationsFolder/vaccinationCenters/index.vue';
import AddVaccinationCenter from '../pages/administrationOfVaccinationsFolder/vaccinationCenters/add';
import EditVaccinationCenter from '../pages/administrationOfVaccinationsFolder/vaccinationCenters/edit';

import VaccinationCentersByMunicipal from '../pages/municipalitiesFolder/vaccinationCenters/index.vue';
import AddVaccinationCenterByMunicipal from '../pages/municipalitiesFolder/vaccinationCenters/add.vue';
import EditVaccinationCenterByMunicipal from '../pages/municipalitiesFolder/vaccinationCenters/edit.vue';

import VaccinationCentersByMunicipalByAdmin from '../pages/administrationOfVaccinationsFolder/municipals/vaccinationCenters.vue';




import VaccinesVaccinationCentersStorage from '../pages/vaccinationCentersFolder/vaccinationCentersStorage/index.vue';
import VaccinesVaccinationCentersStorageByAdmin from '../pages/administrationOfVaccinationsFolder/vaccinationCenters/storage.vue';


import VaccinesVaccinationCentersOrder from '../pages/vaccinationCentersFolder/vaccinationCenterOrders/index.vue';
import AddVaccineToVaccinationCenterOrder from '../pages/vaccinationCentersFolder/vaccinationCenterOrders/add';
import EditVaccineToVaccinationCenterOrder from '../pages/vaccinationCentersFolder/vaccinationCenterOrders/edit';

import VaccinationCenterOrderReadyToDelivery from '../pages/vaccinationCentersFolder/vaccinationCenterOrders/readyToDelivery.vue';



import Vaccines from '../pages/administrationOfVaccinationsFolder/vaccines/index.vue';
import AddVaccine from '../pages/administrationOfVaccinationsFolder/vaccines/add';
import EditVaccine from '../pages/administrationOfVaccinationsFolder/vaccines/edit';

import Citizens from '../pages/administrationOfVaccinationsFolder/Citizens/index.vue';
import AddCitizen from '../pages/administrationOfVaccinationsFolder/Citizens/add';
import EditCitizen from '../pages/administrationOfVaccinationsFolder/Citizens/edit';


import CitizensVaccinationCenter from '../pages/vaccinationCentersFolder/Citizens/index.vue';
import AddCitizenByVaccinationCenter from '../pages/vaccinationCentersFolder/Citizens/add.vue';
import EditCitizenByVaccinationCenter from '../pages/vaccinationCentersFolder/Citizens/edit.vue';
import CitizenFamilyVaccinationCenter from '../pages/vaccinationCentersFolder/Citizens/index_family.vue';
import CitizensVaccinationCenterNotBelong from '../pages/vaccinationCentersFolder/Citizens/not_belong.vue';



import CashingCitizensVaccine from '../pages/vaccinationCentersFolder/givingVaccine/giving.vue';
import CashingCitizenFamilyVaccine from '../pages/vaccinationCentersFolder/givingVaccine/givingFamily.vue';

import CitizensByMunicipal from '../pages/municipalitiesFolder/Citizens/index.vue';
import AddCitizenByMunicipal from '../pages/municipalitiesFolder/Citizens/add.vue';
import EditCitizenByMunicipal from '../pages/municipalitiesFolder/Citizens/edit.vue';


import AdministrationOfVaccinationsOrder from '../pages/administrationOfVaccinationsFolder/orders/index.vue';
import AddAdministrationOfVaccinationsOrder from '../pages/administrationOfVaccinationsFolder/orders/add';
import EditAdministrationOfVaccinationsOrder from '../pages/administrationOfVaccinationsFolder/orders/edit';

import AdministrationOfVaccinationsOrderStateBetweenMedicalSupplies from '../pages/administrationOfVaccinationsFolder/orders/state.vue';




import Cart from '../pages/administrationOfVaccinationsFolder/cart/index.vue';

import CartMedicalSupplies from '../pages/medicalSuppliesFolder/cart/index.vue';


import spoiledVaccinationCenters from '../pages/vaccinationCentersFolder/spoiledVaccine/index.vue';

import SpoiledVaccinationCentersByAdmin from '../pages/administrationOfVaccinationsFolder/vaccinationCenters/spoiled.vue';


import spoiledMunicipalities from '../pages/municipalitiesFolder/spoiledVaccine/index.vue';

import SpoiledMunicipalitiesByAdmin from '../pages/administrationOfVaccinationsFolder/municipals/spoiled.vue';


import spoiledMedicalSupplies from '../pages/medicalSuppliesFolder/spoiledVaccine/index.vue';


import SpoiledMedicalSuppliesByAdmin from '../pages/administrationOfVaccinationsFolder/medicalSupplies/spoiled.vue';


import printCitizensCertificatesByAdmin from '../pages/administrationOfVaccinationsFolder/Citizens/print.vue';
import printCitizensCertificatesvaccinationCenters from '../pages/vaccinationCentersFolder/Citizens/print.vue';


import printCitizensCertificates from '../pages/citizensFolder/certificates/print.vue';
import citizensRegister from '../pages/citizensFolder/register.vue';



import reportVaccinationCenters from '../pages/vaccinationCentersFolder/report/report.vue';
import printPeriodicReportByVaccinationCenters from '../pages/vaccinationCentersFolder/report/printPeriodicReport.vue';
import printCitizenVaccinationReportByVaccinationCenters from '../pages/vaccinationCentersFolder/report/printCitizenVaccinationReport.vue';








import home from '../pages/web/home.vue';
// import about from '../pages/web/about.vue';
import about from '../pages/web/about2.vue';


import not_found_web from '../pages/web/404.vue';
import branches from '../pages/web/branches.vue';
import complaint_report from '../pages/web/complaint_report.vue';
import complaint from '../pages/web/complaint.vue';
import contact from '../pages/web/contact.vue';
import decisions from '../pages/web/decisions.vue';
import legislation from '../pages/web/legislation.vue';
import news from '../pages/web/news.vue';
import noResult from '../pages/web/noResult.vue';
import report_form from '../pages/web/report_form.vue';
import reports from '../pages/web/reports.vue';
import research_studies from '../pages/web/research_studies.vue';
import gallery from '../pages/web/gallery.vue';





Vue.use(VueRouter)

const routes = [

    {
        name: 'home',
        path: '/',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: home,

    },
    {
        name: 'about',
        path: '/about',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: about,

    },
    {
        name: 'not_found_web',
        path: '/not_found_web',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: not_found_web,

    },
    {
        name: 'branches',
        path: '/branches',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: branches,

    },
    {
        name: 'complaint_report',
        path: '/complaint_report',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: complaint_report,

    },
    {
        name: 'complaint',
        path: '/complaint',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: complaint,

    },
    {
        name: 'contact',
        path: '/contact',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: contact,

    },
    {
        name: 'decisions',
        path: '/decisions',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: decisions,

    },
    {
        name: 'legislation',
        path: '/legislation',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: legislation,

    },
    {
        name: 'news',
        path: '/news',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: news,

    },
    {
        name: 'noResult',
        path: '/noResult',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: noResult,

    },
    {
        name: 'report_form',
        path: '/report_form',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: report_form,

    },
    {
        name: 'reports',
        path: '/reports',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: reports,

    },
    {
        name: 'research_studies',
        path: '/research_studies',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: research_studies,

    },
    {
        name: 'gallery',
        path: '/gallery',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: gallery,

    },





    {
        name: 'login',
        path: '/login',
        // meta: { DashboardLayout: false},
        meta: { DashboardLayout: false },
        component: Login,

    },

    {
        name: 'googlemaps',
        path: '/googlemaps',
        meta: { DashboardLayout: false },
        component: googlemaps
    },



    {
        name: 'citizensRegister',
        path: '/citi00/register',
        meta: { DashboardLayout: false },
        component: citizensRegister
    },

    {
        name: 'printCitizensCertificates',
        path: '/citi00/certificates/:id',
        meta: { DashboardLayout: false },
        component: printCitizensCertificates,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },




    {
        name: 'reportVaccinationCenters',
        path: '/vc2030/report',
        meta: { DashboardLayout: true },
        component: reportVaccinationCenters,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },





    {
        name: 'printPeriodicReportByVaccinationCenters',
        path: '/vc2030/report/period/:period/:id',
        meta: { DashboardLayout: false },
        component: printPeriodicReportByVaccinationCenters,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'printCitizenVaccinationReportByVaccinationCenters',
        path: '/vc2030/report/citizen/:period/:id',
        meta: { DashboardLayout: false },
        component: printCitizenVaccinationReportByVaccinationCenters,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },



    {
        name: 'printCitizensCertificatesByAdmin',
        path: '/ad1020/certificates/:id',
        meta: { DashboardLayout: true },
        component: printCitizensCertificatesByAdmin,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },


    {
        name: 'printCitizensCertificatesvaccinationCenters',
        path: '/vc2030/certificates/:id',
        meta: { DashboardLayout: false },
        component: printCitizensCertificatesvaccinationCenters,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },




    {
        name: 'vaccinesVaccinationCentersStorageByAdmin',
        path: '/ad1020/vaccination-centers/:id/storage',
        meta: { DashboardLayout: true },
        component: VaccinesVaccinationCentersStorageByAdmin,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'spoiledVaccinationCentersByAdmin',
        path: '/ad1020/vaccination-centers/:id/spoiled',
        meta: { DashboardLayout: true },
        component: SpoiledVaccinationCentersByAdmin,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },





    {
        name: 'vaccinationCentersByMunicipalByAdmin',
        path: '/ad1020/municipals/:id/affiliate-vaccination-centers',
        meta: { DashboardLayout: true },
        component: VaccinationCentersByMunicipalByAdmin,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'vaccinesMunicipalsStorageByAdmin',
        path: '/ad1020/municipals/:id/storage',
        meta: { DashboardLayout: true },
        component: VaccinesMunicipalsStorageByAdmin,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'spoiledMunicipalitiesByAdmin',
        path: '/ad1020/municipals/:id/spoiled',
        meta: { DashboardLayout: true },
        component: SpoiledMunicipalitiesByAdmin,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },




    {
        name: 'vaccinesMedicalsuppliesStorageByAdmin',
        path: '/ad1020/medical-supplies/:id/storage',
        meta: { DashboardLayout: true },
        component: VaccinesMedicalsuppliesStorageByAdmin,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'spoiledMedicalSuppliesByAdmin',
        path: '/ad1020/medical-supplies/:id/spoiled',
        meta: { DashboardLayout: true },
        component: SpoiledMedicalSuppliesByAdmin,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },



    {
        name: 'test',
        path: '/test',
        meta: { DashboardLayout: false },
        component: test
    },

    {
        name: 'ChartJS',
        path: '/ChartJS',
        meta: { DashboardLayout: false },
        component: ChartJS
    },

    {
        name: 'certificate',
        path: '/certificate',
        meta: { DashboardLayout: false },
        component: certificate
    },



    {
        name: 'notFound',
        path: '/notFound',
        meta: { DashboardLayout: false },
        component: NotFound
    },


    {
        name: 'register',
        path: '/register',
        meta: { DashboardLayout: false },

        // meta: { middleware: [guest] },
        component: Register
    },

    {
        name: 'dashboard',
        path: '/dashboard',
        meta: { DashboardLayout: true },
        component: Dashboard,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'dashboardAdministrationOfVaccinations',
        path: '/ad1020/dashboard',
        meta: { DashboardLayout: true },
        component: DashboardAdministrationOfVaccinations,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'dashboardVaccinationCenters',
        path: '/vc2030/dashboard',
        meta: { DashboardLayout: true },
        component: DashboardVaccinationCenters,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },


    {
        name: 'dashboardMedicalSupplies',
        path: '/ms3040/dashboard',
        meta: { DashboardLayout: true },
        component: DashboardMedicalSupplies,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'dashboardMunicipalities',
        path: '/m4050/dashboard',
        meta: { DashboardLayout: true },
        component: DashboardMunicipalities,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },







    {
        name: 'profile',
        path: '/profile',
        meta: { DashboardLayout: true },
        component: profile
            // beforeEnter: (to, form, next) => {
            //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
            //     else next()
            // }

    },
    {
        name: 'editprofile',
        path: '/profile/edit/:id',
        meta: { DashboardLayout: true },
        component: EditProfile
            // beforeEnter: (to, form, next) => {
            //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
            //     else next()
            // }
    },


    {
        name: 'administrators',
        path: '/ad1020/administrators',
        meta: { DashboardLayout: true },
        component: Administrators,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addadministrator',
        path: '/ad1020/administrators/add',
        meta: { DashboardLayout: true },
        component: AddAdministrators,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editadministrator',
        path: '/ad1020/administrators/edit/:id',
        meta: { DashboardLayout: true },
        component: EditAdministrators,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'administratorsByMunicipal',
        path: '/m4050/administrators/:municipal_id',
        meta: { DashboardLayout: true },
        component: AdministratorsByMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },



    {
        name: 'nationalities',
        path: '/ad1020/nationalities',
        meta: { DashboardLayout: true },
        component: Nationalities,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addnationality',
        path: '/ad1020/nationalities/add',
        meta: { DashboardLayout: true },
        component: AddNationality,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editnationality',
        path: '/ad1020/nationalities/edit/:id',
        meta: { DashboardLayout: true },
        component: EditNationality,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'medicalsupplies',
        path: '/ad1020/medical-supplies',
        meta: { DashboardLayout: true },
        component: MedicalSupplies,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addMedicalSupply',
        path: '/ad1020/medical-supplies/add',
        meta: { DashboardLayout: true },
        component: AddMedicalSupply,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editMedicalSupply',
        path: '/ad1020/medical-supplies/edit/:id',
        meta: { DashboardLayout: true },
        component: EditMedicalSupply,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'medicalSuppliesOrder',
        path: '/ms3040/medical-supplies/:id/order',
        meta: { DashboardLayout: true },
        component: MedicalSuppliessOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addMedicalSupplyOrder',
        path: '/ms3040/medical-supplies/:id/order/add-order',
        meta: { DashboardLayout: true },
        component: AddMedicalSupplyOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editMedicalSupplyOrder',
        path: '/ms3040/medical-supplies/:id/order/:order',
        meta: { DashboardLayout: true },
        component: EditMedicalSupplyOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'vaccinesMedicalsuppliesStorage',
        path: '/ms3040/medical-supplies/:id/storage',
        meta: { DashboardLayout: true },
        component: VaccinesMedicalsuppliesStorage,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addVaccineToMedicalSupplyStorage',
        path: '/ms3040/medical-supplies/:id/storage/add-vaccine',
        meta: { DashboardLayout: true },
        component: AddVaccineToMedicalSupplyStorage,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'deliveryvaccinesMedicalSuppliesOrder',
        path: '/ms3040/medical-supplies/:id/order/delivery',
        meta: { DashboardLayout: true },
        component: DeliveryVaccinesMedicalSuppliesOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },


    {
        name: 'storageMedicalsupplies',
        path: '/ms3040/storage/:id',
        meta: { DashboardLayout: true },
        component: VaccinesMedicalsuppliesStorage,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addStorageVaccineToMedicalSupply',
        path: '/storage/:id/add-vaccine',
        meta: { DashboardLayout: true },
        component: AddVaccineToMedicalSupplyStorage,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'municipals',
        path: '/ad1020/municipals',
        meta: { DashboardLayout: true },
        component: Municipals,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addMunicipal',
        path: '/ad1020/municipals/add',
        meta: { DashboardLayout: true },
        component: AddMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editMunicipal',
        path: '/ad1020/municipals/edit/:id',
        meta: { DashboardLayout: true },
        component: EditMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'vaccinesMunicipalsStorage',
        path: '/m4050/municipals/:id/storage',
        meta: { DashboardLayout: true },
        component: VaccinesMunicipalsStorage,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'vaccinesMunicipalsOrder',
        path: '/m4050/municipals/:id/order',
        meta: { DashboardLayout: true },
        component: VaccinesMunicipalsOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addVaccineToMunicipalOrder',
        path: '/m4050/municipals/:id/order/add-order',
        meta: { DashboardLayout: true },
        component: AddVaccineToMunicipalOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editVaccineToMunicipalOrder',
        path: '/m4050/municipals/:id/order/:order',
        meta: { DashboardLayout: true },
        component: EditVaccineToMunicipalOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'municipalsOrderReadyToDelivery',
        path: '/m4050/ready-to-delivery',
        meta: { DashboardLayout: true },
        component: MunicipalsOrderReadyToDelivery,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'deliveryvaccinesMunicipalsOrder',
        path: '/m4050/municipals/:id/order/delivery',
        meta: { DashboardLayout: true },
        component: DeliveryVaccinesMunicipalsOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'addadministratorfrommunicipals',
        path: '/m4050/administrators/add',
        meta: { DashboardLayout: true },
        component: AddAdministratorsFromMunicipals,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },



    {
        name: 'vaccinationCenters',
        path: '/ad1020/vaccination-centers',
        meta: { DashboardLayout: true },
        component: VaccinationCenters,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'vaccinationCentersByMunicipal',
        path: '/m4050/vaccination-centers/:municipal_id',
        meta: { DashboardLayout: true },
        component: VaccinationCentersByMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addVaccinationCenterByMunicipal',
        path: '/m4050/vaccination-centers/:municipal_id/add',
        meta: { DashboardLayout: true },
        component: AddVaccinationCenterByMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'editVaccinationCenterByMunicipal',
        path: '/m4050/vaccination-centers/:municipal_id/edit/:id',
        meta: { DashboardLayout: true },
        component: EditVaccinationCenterByMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'addVaccinationCenter',
        path: '/ad1020/vaccination-centers/add',
        meta: { DashboardLayout: true },
        component: AddVaccinationCenter,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editVaccinationCenter',
        path: '/ad1020/vaccination-centers/edit/:id',
        meta: { DashboardLayout: true },
        component: EditVaccinationCenter,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'vaccinesVaccinationCentersStorage',
        path: '/vc2030/vaccination-centers/:id/storage',
        meta: { DashboardLayout: true },
        component: VaccinesVaccinationCentersStorage,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'vaccinesVaccinationCentersOrder',
        path: '/vc2030/vaccination-centers/:id/order',
        meta: { DashboardLayout: true },
        component: VaccinesVaccinationCentersOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addVaccineToVaccinationCenterOrder',
        path: '/vc2030/vaccination-centers/:id/order/add-order',
        meta: { DashboardLayout: true },
        component: AddVaccineToVaccinationCenterOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editVaccineToVaccinationCenterOrder',
        path: '/vc2030/vaccination-centers/:id/order/:order',
        meta: { DashboardLayout: true },
        component: EditVaccineToVaccinationCenterOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'vaccinationCenterOrderReadyToDelivery',
        path: '/vc2030/ready-to-delivery',
        meta: { DashboardLayout: true },
        component: VaccinationCenterOrderReadyToDelivery,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },





    {
        name: 'vaccines',
        path: '/ad1020/vaccines',
        meta: { DashboardLayout: true },
        component: Vaccines,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addVaccine',
        path: '/ad1020/vaccines/add',
        meta: { DashboardLayout: true },
        component: AddVaccine,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editVaccine',
        path: '/ad1020/vaccines/edit/:id',
        meta: { DashboardLayout: true },
        component: EditVaccine,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },


    {
        name: 'citizens',
        path: '/ad1020/citizens',
        meta: { DashboardLayout: true },
        component: Citizens,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addCitizen',
        path: '/ad1020/citizens/add',
        meta: { DashboardLayout: true },
        component: AddCitizen,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editCitizen',
        path: '/ad1020/citizens/edit/:id',
        meta: { DashboardLayout: true },
        component: EditCitizen,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'citizenFamily',
        path: '/vc2030/citizens/:id/family/:registration_number',
        meta: { DashboardLayout: true },
        component: CitizenFamilyVaccinationCenter,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'citizenFamilyVaccine',
        path: '/vc2030/citizens/:id/family/:registration_number',
        meta: { DashboardLayout: true },
        component: CashingCitizenFamilyVaccine,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },


    {
        name: 'citizens_municipal',
        path: '/m4050/citizens/:municipal_id',
        meta: { DashboardLayout: true },
        component: CitizensByMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addCitizenByMunicipal',
        path: '/m4050/citizens/:municipal_id/add',
        meta: { DashboardLayout: true },
        component: AddCitizenByMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editCitizenByMunicipal',
        path: '/m4050/citizens/:municipal_id/edit/:id',
        meta: { DashboardLayout: true },
        component: EditCitizenByMunicipal,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },


    {
        name: 'citizens_vaccination_center',
        path: '/vc2030/citizens/:vaccination_center_id',
        meta: { DashboardLayout: true },
        component: CitizensVaccinationCenter,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'add_citizen_by_vaccination_center',
        path: '/vc2030/citizens/:vaccination_center_id/add',
        meta: { DashboardLayout: true },
        component: AddCitizenByVaccinationCenter,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'edit_citizen_by_vaccination_center',
        path: '/vc2030/citizens/:vaccination_center_id/edit/:id',
        meta: { DashboardLayout: true },
        component: EditCitizenByVaccinationCenter,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'cashing_citizens_vaccine',
        path: '/vc2030/citizens/:id/vaccines/:vaccination_center_id/:date_of_birth',
        meta: { DashboardLayout: true },
        component: CashingCitizensVaccine,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'citizens_vaccination_center_not_belong',
        path: '/vc2030/citizens/:vaccination_center_id/not-belong',
        meta: { DashboardLayout: true },
        component: CitizensVaccinationCenterNotBelong,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },







    {
        name: 'administrationOfVaccinationsOrder',
        path: '/ad1020/:id/order',
        meta: { DashboardLayout: true },
        component: AdministrationOfVaccinationsOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'addAdministrationOfVaccinationsOrder',
        path: '/ad1020/:id/order/add-order',
        meta: { DashboardLayout: true },
        component: AddAdministrationOfVaccinationsOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'editAdministrationOfVaccinationsOrder',
        path: '/ad1020/:id/order/:order',
        meta: { DashboardLayout: true },
        component: EditAdministrationOfVaccinationsOrder,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'administrationOfVaccinationsOrderStateBetweenMedicalSupplies',
        path: '/ad1020/state-orders',
        meta: { DashboardLayout: true },
        component: AdministrationOfVaccinationsOrderStateBetweenMedicalSupplies,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },


    {
        name: 'cart',
        path: '/ad1020/cart',
        meta: { DashboardLayout: true },
        component: Cart,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },

    {
        name: 'cart_medical_supplies',
        path: '/ms3040/cart',
        meta: { DashboardLayout: true },
        component: CartMedicalSupplies,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },


    {
        name: 'SpoiledVaccinationCenters',
        path: '/vc2030/vaccination-centers/:id/spoiled',
        meta: { DashboardLayout: true },
        component: spoiledVaccinationCenters,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'SpoiledMunicipals',
        path: '/m4050/municipals/:id/spoiled',
        meta: { DashboardLayout: true },
        component: spoiledMunicipalities,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },
    {
        name: 'SpoiledMedicalSupplies',
        path: '/ms3040/medical-supplies/:id/spoiled',
        meta: { DashboardLayout: true },
        component: spoiledMedicalSupplies,
        // beforeEnter: (to, form, next) => {
        //     if (localStorage.getItem('login') != 'true') next({ name: 'login' })
        //     else next()
        // }
    },






]


const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router