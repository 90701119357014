import * as API from "./API";

import store from "../store";

export default {
    // getMedicalSuppliesWithoutPagination(is_approved, id) {
    //     return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`);
    // },

    getMedicalSupplies(page_number, page_size, is_approved, id) {
        return API.apiClient.get(`/api/Order/get_order_for_muncipal?page_number=${page_number}&page_size=${page_size}&is_approved=${is_approved}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // getMedicalSuppliesById(id) {
    //     return API.apiClient.get(`/api/Order/Order?id=${id}`);
    // },

    createMedicalSupplies(order) {
        return API.apiClient.post(`/api/Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    orderApproval(id) {
        return API.apiClient.get(`/api/Order/change_state_of_order_from_muncipla?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    // updateMedicalSupplies(order) {
    //     return API.apiClient.put(`/api/Order`, order);
    // },

};