<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
                تسليم اللقاحات
            </h1>

           
        </div>



        <div class="mt-4 flex items-center justify-between w-full relative">  
            <div class="h-8">
                <button @click="toggle_filter()" class="flex items-center hover:text-blue-600 cursor-pointer w-32">
                    <svg class="h-6 w-6 fill-current " xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32">
                        <path d="M2 7h.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2H9.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2zm4-3a2 2 0 1 1-2 2 2 2 0 0 1 2-2zm24 11h-.142a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h20.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-4 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2zm4 7H19.858a3.981 3.981 0 0 0-7.716 0H2a1 1 0 0 0 0 2h10.142a3.981 3.981 0 0 0 7.716 0H30a1 1 0 0 0 0-2zm-14 3a2 2 0 1 1 2-2 2 2 0 0 1-2 2z"/>
                    </svg>
                    <span class="mr-2">
                        فرز
                    </span>
                </button>
            </div>

            <div class="hidden xl:flex xl:ml-0 h-8">
                <label for="search-field" class="sr-only">Search</label>
                <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center">
                        <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                            <path
                                fill-rule="evenodd"
                                d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                                clip-rule="evenodd"
                            />
                        </svg>
                    </div>
                    <input
                        id="search-field"
                        class="block h-full bg-gray-50 dark:bg-slate-900 w-full border-transparent py-2 pr-8 dark:text-gray-400 text-gray-900 placeholder-gray-500 focus:outline-none focus:placeholder-gray-400 focus:ring-0 focus:border-transparent sm:text-sm"
                        placeholder="بحث...."
                        type="search"
                        name="search"
                        v-model="filter_search"
                    />
                </div>
            </div>

            <transition
                enter-active-class="transition ease-in-out duration-200"
                enter-class="transform opacity-0 scale-y-0"
                enter-to-class="transform opacity-100 scale-y-100"
                leave-active-class="transition ease-in-out duration-75"
                leave-class="transform opacity-100 scale-y-100"
                leave-to-class="transform opacity-0 scale-y-0"
            >

                <div v-if="filter_box" class="absolute z-40 w-full top-8 sm:rounded-b-lg border-t-2 border-gray-700 dark:border-slate-400 bg-gray-300 dark:bg-slate-700 dark:bg-opacity-90 bg-opacity-90 px-4 pt-4 pb-8">

                    <div class="grid grid-cols-4 gap-8">
                        <div class="">
                            <label for="medical_supply_id" class="block text-sm font-medium ">
                                    موافقة ادارة التطعيمات
                            </label>
                            <select
                                id="is_approved"
                                name="is_approved"
                                v-model="filter_is_approved"
                                class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            >
                                <option  disabled selected>الحالات</option>

                                <option v-bind:value="0">
                                    الكل
                                </option>

                                <option v-bind:value="1">
                                    تمت الموافقة
                                </option>

                                <option v-bind:value="2">
                                    تم الرفض
                                </option>

                                <option v-bind:value="3">
                                    قيد المعالجة
                                </option>

                            </select>
                        </div>
                    </div>

                </div>

            </transition>
        </div>




        <div class="py-2 align-middle inline-block min-w-full mt-6">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                    <div class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right">
                        <div scope="col" class="w-1/12 px-6 py-3 hidden lg:block">
                            الرقم
                        </div>
                        <div scope="col" class="w-7/12 md:w-2/12 px-6 py-3">
                            الاسم
                        </div>
                        
                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            المركز الصحي
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            إدارة التطعيمات
                        </div>

                        <div scope="col" class="w-3/12 md:w-1/12 px-6 py-3 hidden md:block">
                            الكمية
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            تاريخ الطلب
                        </div>

                        <div scope="col" class="w-5/12 md:w-2/12 px-6 py-3">
                            الإجراءات
                        </div>
                    </div>
                    <div v-for="(order, personIdx) in vaccination_center_orders_data" :key="order.id"
                        class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400" 
                        :class=" personIdx % 2 === 0 ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-800' "
                    >
                        <div class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                            {{ personIdx+1 }}
                        </div>

                        <div class="w-7/12 md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate">
                            {{ order.vaccine_name }} 
                        </div>

                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ order.health_Center_name }}
                        </div>

                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            <span v-if="order.center_approval == true">
                                تمت الموافقة
                            </span>
                            <span v-else>
                                لم تتم الموافقة
                            </span>
                        </div>

                        <div class="md:w-1/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ order.amount }}
                        </div>

                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ order.date_of_order }}
                        </div>

                        <div class="w-5/12 md:w-2/12 px-6 py-4">
                            <div class="w-full flex items-center justify-between">
                                <div class="w-12">
                                    <button title="تسليم الشحنة" @click="orderDeliveryTovaccinationCenter(order)">
                                        <svg class="w-6 h-6 fill-current hover:text-green-600" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 64 64" viewBox="0 0 64 64">
                                            <path d="M37.8 4.3c0-.6-.5-1-1-1h-5.9C30.1 1.3 28.2 0 26 0c-3 0-5.3 2.4-5.3 5.3h16.2C37.4 5.3 37.8 4.9 37.8 4.3zM30.1 10.3v-3h-8.4v2.2c0 2.2 1.8 4 4 4h1.2C28.7 13.5 30.1 12.1 30.1 10.3zM46.9 21.3c.2 0 .5.1.7.2l1.3 1.1V19H45v3.6l1.3-1.1C46.4 21.4 46.6 21.3 46.9 21.3zM24.3 15.4L24.2 17h2.4l.2-1.4h-1.2C25.2 15.6 24.8 15.5 24.3 15.4zM42.5 56.1c0 0-.1 0-.2 0l0 0h-.1l-3 .3c-.2 0-.4 0-.6 0-.4-.1-.8-.2-1.1-.5-.5-.4-.8-1-.9-1.6l-.5-5.5c-.1-.6-.2-1.1-.4-1.7-.1-.2-.2-.4-.3-.6L31.2 40l-1.7-2.7h-5.7c-.6 0-1-.5-1-1 0-.6.5-1 1-1h5.3v-6h-1.2c-.4 0-.8-.3-.9-.6-.1-.1-.1-.3-.1-.4 0-.6.5-1 1-1h2.2c.1 0 .3 0 .4.1l.1.1c.1 0 .1.1.2.1l3 2.9c.5.5 1.2.8 1.9.8h6.8c.3 0 .5-.1.7-.3l0 0c.1 0 .1-.1.1-.2.1-.2.2-.4.2-.6 0-.3-.1-.5-.3-.7s-.4-.3-.7-.3h-5.3c-1.4 0-2.8-.6-3.8-1.5l-.1-.1c-.1-.1-.1-.1-.2-.2-.1-.1-.2-.2-.3-.3L32.7 27c-.1-.2-.2-.3-.3-.5l-.3-.6L29 20c0 0 0-.1-.1-.1-.1-.1-.2-.2-.3-.3-.1-.1-.1-.1-.2-.2-.1 0-.1-.1-.2-.1-.1-.1-.2-.1-.3-.1-.1 0-.1-.1-.2-.1S27.5 19 27.4 19c-.1 0-.2 0-.3 0H27h-2.8c-.3 0-.5.1-.8.1-.2.1-.4.2-.5.3 0 0 0 0-.1.1-.2.1-.3.2-.4.4l0 0c-.1.1-.2.3-.3.4 0 .1-.1.1-.1.2-.1.1-.1.3-.1.4V21l-2 18.2L19 47.7c0 .4-.1.7-.2 1.1-.4 1.5-1.1 2.9-2.2 4.1l-2.5 2.5c-.1.1-.2.3-.2.5s.1.5.2.7l5.2 5.2c.2.2.6.3.9.2.1 0 .2-.1.3-.2.1-.1.1-.2.2-.3.1-.2.1-.4 0-.6 0-.1-.1-.2-.1-.3l-1-1.4L19.4 59l0 0c-.7-1-.7-2.2-.1-3.3 0 0 0-.1.1-.1l1.9-2.7c.1-.1.1-.2.2-.3s.1-.2.2-.3c.1-.2.2-.3.2-.5 0-.1.1-.1.1-.2.2-.4.3-.9.4-1.4l1.6-8.9c0-.2.2-.4.4-.6.1-.1.3-.2.4-.2s.1 0 .2 0h.1c.1 0 .3.1.4.1.1.1.3.2.4.3l.7 1 5.7 8.4c.6.9 1 1.9 1.1 3 0 .1 0 .2 0 .3l.6 5.6c0 .1 0 .1.1.1 0 0 0 0 0 .1l.1.1c.1 0 .2.1.3 0l2.4-.9 5.6-2.1c.1 0 .1-.1.2-.1 0-.1.1-.1.1-.2 0-.2-.1-.2-.1-.3C42.6 56.1 42.6 56.1 42.5 56.1z"/>
                                            <path d="M55.2 19h-4.3v5.8c0 .4-.2.8-.6.9-.1.1-.3.1-.4.1-.2 0-.5-.1-.7-.2l-2.3-1.9-2.3 1.9c-.3.3-.7.3-1.1.1-.4-.2-.6-.5-.6-.9V19h-4.3c-1.1 0-2 .9-2 2v6c.2.1.5.1.7.1h5.3c.8 0 1.6.3 2.2.9s.9 1.3.9 2.2c0 .8-.3 1.6-.9 2.2-.1.1-.3.2-.4.3 0 0 0 0-.1.1S44.1 32.9 44 33c-.1 0-.1.1-.2.1-.1 0-.1 0-.2.1-.3.1-.6.2-1 .2h-5.8V35c.3.8 1 1.2 1.9 1.2h16.6c1.1 0 2-.9 2-2V21C57.2 19.9 56.3 19 55.2 19zM59.9 62H4.1c-.6 0-1 .5-1 1s.5 1 1 1H60c.6 0 1-.5 1-1S60.5 62 59.9 62z"/>
                                        </svg>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </table>
            </div>
        </div>

        <div class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative">
            <pagination
                dir="rtl"
                v-model="page_number"
                :per-page="page_size"
                :records="total"
                @paginate="getMunicipalStorageApproved"
                class="z-10"
            />
        </div>
    </div>
</template>

<script>
import MunicipalsOrdersService from "@/services/MunicipalsOrdersService";

export default {
    computed: {},
    data() {
        return {
            vaccination_center_orders_data: {},
            page_number: 1,
            page_size: 10,
            total: 0,

            vaccination_center: this.$route.params.id,


            filter_box: false,

            filter_search: '',
            filter_is_approved: '',
        };
    },

    watch: {
        // filter_search: function() {
        //     this.getCitizens()
        // },
        filter_is_approved: function() {
            if(this.filter_is_approved == 0){
                this.filter_is_approved = '';
            }
            this.getMunicipalStorageApproved()
        },
    },

    created() {
        this.getMunicipalStorageApproved();
    },
    methods: {


        orderDeliveryTovaccinationCenter(order) {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            MunicipalsOrdersService.orderDeliveryTovaccinationCenter(order)
                .then((resp) => {
                    this.getMunicipalStorageApproved();
                    setTimeout(() => {

                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })

                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.message
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.message,
                    })
                });
        },


        toggle_filter(){
            this.filter_box = !this.filter_box;
        },
   
        getMunicipalStorageApproved() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            MunicipalsOrdersService.getMunicipalStorageApproved(
                this.page_number,
                this.page_size,
                this.$route.params.id,
            )
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();
                        this.vaccination_center_orders_data = res.data.list;
                        this.total = res.data.total;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

       
    },
};
</script>


<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: .5rem;
        margin-right: .5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
