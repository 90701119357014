<template>
    <div dir="rtl">
        <div v-if="$route.meta.DashboardLayout" class="">
            <LayoutContent></LayoutContent>
        </div>
        <div v-else>
            <LayoutBlank> </LayoutBlank>
        </div>
    </div>
</template>

<script>
import LayoutBlank from "./layouts/Blank.vue";
import LayoutContent from "./layouts/Content.vue";

export default {
    name: "App",
    
    components: {
        LayoutBlank,
        LayoutContent,
    },

    data() {
        return {};
    },

    created() {},

    mounted() {},

    computed: {},

    methods: {},
};
</script>
