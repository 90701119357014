<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
                المخزن
            </h1>

 

        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                    <div class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right">
                        <div scope="col" class="w-1/12 px-6 py-3 hidden lg:block">
                            الرقم
                        </div>
                        <div scope="col" class="w-7/12 md:w-4/12 px-6 py-3">
                            الاسم
                        </div>
                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            الكمية
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            تاريخ الانتاج
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            إنتهاء الصلاحية
                        </div>

                        <div scope="col" class="w-3/12 md:w-2/12 px-6 py-3 hidden md:block">
                            تاريخ الاضافة
                        </div>

                        
                    </div>
                    <div v-for="(vaccine, personIdx) in medical_supplies_storage_data" :key="vaccine.id"
                        class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400" 
                        :class=" personIdx % 2 === 0 ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-800' "
                    >
                        <div class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                            {{ personIdx+1 }}
                        </div>
                        <div class="w-7/12 md:w-4/12 px-6 py-4 whitespace-nowrap text-sm truncate">
                            {{ vaccine.vaccine_name }} 
                        </div>
                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ vaccine.quantity }}
                        </div>

                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ vaccine.expired_date }}
                        </div>

                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ vaccine.expired_date }}
                        </div>

                        <div class="md:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ vaccine.insert_at }}
                        </div>

                        
                    </div>
                </table>
            </div>
        </div>

        <div class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative">
            <pagination
                dir="rtl"
                v-model="page_number"
                :per-page="page_size"
                :records="total"
                @paginate="VaccinationsStorage"
                class="z-10"
            />
        </div>



        <div v-if="spoiled_vaccine_model" class="fixed z-40 bg-black bg-opacity-60 inset-0 ">
            <div class="min-h-screen flex justify-center items-center">
                <form class="w-1/3  bg-white py-4" @submit.prevent="createSpoiledVaccine">
                
                    <div class="px-4 flex justify-between items-center">
                       <div class="">
                            اللقاح التألف
                       </div>
                       <div class="">
                            <button @click="spoiled_vaccine_model = false" class="hover:text-red-500 cursor-pointer">X</button>
                       </div>
                    </div>

                    <hr class="bg-gray-400 h-0.5 mt-4">

                    <div class="input mt-6 px-4" :class="{ invalid: $v.spoiled_vaccine_obj.quantity.$error }">
                        <label for="spoiled_vaccine_obj_quantity" class="block text-sm font-medium ">
                            الكمية
                        </label>
                        <input
                            type="number"
                            id="spoiled_vaccine_obj_quantity"
                            placeholder="أكتب الكمية."
                            v-model="spoiled_vaccine_obj.quantity"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3  bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.spoiled_vaccine_obj.quantity.$touch()"
                        />
                       
                    </div>


                    <div class="input mt-6 px-4" :class="{ invalid: $v.spoiled_vaccine_obj.reason.$error }">
                        <label for="spoiled_vaccine_obj_reason" class="block text-sm font-medium ">
                            السبب
                        </label>
                        <input
                            type="text"
                            id="spoiled_vaccine_obj_reason"
                            placeholder="أكتب السبب."
                            v-model="spoiled_vaccine_obj.reason"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3  bg-white focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.spoiled_vaccine_obj.reason.$touch()"
                        />
                       
                    </div>

                    <div class="text-white md:w-auto mt-8 px-4 w-full">
                        <div class="w-full md:w-auto" :class=" $v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer' ">
                            <button
                                :disabled="$v.$invalid"
                                type="submit"
                                class="border border-transparent rounded-md shadow-sm w-full py-2 px-16 text-sm font-medium focus:outline-none"
                                :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                                إضافة
                            </button>
                        </div>
                    </div>
                    
                </form>
            </div>
        </div>



    </div>
</template>

<script>
import VaccinationsStorageService from "@/services/VaccinationsStorageService";
import SpoiledVaccineService from "@/services/SpoiledVaccineService";
import { required } from "vuelidate/lib/validators";


export default {
    computed: {},
    data() {
        return {
            medical_supplies_storage_data: {},
            page_number: 1,
            page_size: 10,
            total: 0,


            spoiled_vaccine_model: false,


            spoiled_vaccine_obj:{
                quantity:'',
                health_Center_stroge_id:'',
                vaccine_id:'',
                reason: ''
            },

        };
    },

    validations: {
        spoiled_vaccine_obj: {
            quantity: {
                required,
            },
            health_Center_stroge_id: {
                required,
            },
            vaccine_id: {
                required,
            },
            reason: {
                required,
            },
            
            
        },
    },

    created() {
        this.VaccinationsStorage();
    },
    methods: {
        
        spoiledVaccine(id, vaccineid){
            this.spoiled_vaccine_model = true
            this.spoiled_vaccine_obj.health_Center_stroge_id = id ;
            this.spoiled_vaccine_obj.vaccine_id = vaccineid ;
        },



        createSpoiledVaccine() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                vaccineid: this.spoiled_vaccine_obj.vaccine_id,
                health_center_Storages_id: this.spoiled_vaccine_obj.health_Center_stroge_id,
                quantity: this.spoiled_vaccine_obj.quantity,
                reason: this.spoiled_vaccine_obj.reason,
            }

                SpoiledVaccineService.createSpoiledVaccineForVaccinationCenters(data)
                    .then((resp) => {
                        this.spoiled_vaccine_model = false
                        this.VaccinationsStorage();

                        this.spoiled_vaccine_obj.health_Center_stroge_id = "" ;
                        this.spoiled_vaccine_obj.vaccine_id = "" ;
                        this.spoiled_vaccine_obj.quantity = "" ;
                        this.spoiled_vaccine_obj.reason = "" ;



                        setTimeout(() => {
                            loader.hide();
                            this.$swal.fire({
                                icon: 'success',
                                text: resp.data.message,
                            })
                        }, 10);
                    })
                    .catch((err) => {   
                        this.spoiled_vaccine_model = false

                        loader.hide();

                        this.spoiled_vaccine_obj.health_Center_stroge_id = "" ;
                        this.spoiled_vaccine_obj.vaccine_id = "" ;
                        this.spoiled_vaccine_obj.quantity = "" ;
                        this.spoiled_vaccine_obj.reason = "" ;
                        
                        this.errors = err.response.data.errors
                        this.$swal.fire({
                            icon: 'error',
                            title: '...عذرا',
                            text: err.response.data.errors.name,
                        })
                    });
            
        },


        VaccinationsStorage() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            VaccinationsStorageService.getVaccinationsStorage(
                this.page_number,
                this.page_size,
                this.$route.params.id
            )
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.medical_supplies_storage_data = res.data.list;
                        this.total = res.data.total;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        deleteMedicalSupplyStorage(id) {
            this.$swal
                .fire({
                    title: "هل أنت متأكد؟",
                    text: "!لن تتمكن من التراجع عن هذا",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    cancelButtonText: "إلغاء",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "!نعم ، احذفها",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let loader = this.$loading.show({
                            loader: this.loader,
                        });

                        VaccinationsStorageService.deleteMedicalSupplyStorage(id)
                            .then((resp) => {
                                let i = this.medical_supplies_storage_data
                                    .map((item) => item.id)
                                    .indexOf(id); // find index of your object
                                this.medical_supplies_storage_data.splice(i, 1);
                                loader.hide();
                                this.$swal.fire({
                                    text: resp.data.message,
                                    icon: "success",
                                    confirmButtonText: "حسنا",
                                });
                            })
                            .catch((err) => {
                                loader.hide();

                                this.$swal.fire({
                                    icon: "error",
                                    title: "...عذرا",
                                    text: err.response.data.errors.name,
                                });
                            });
                    }
                });
        },
    },
};
</script>


<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  margin-left: .5rem;
        margin-right: .5rem;
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
