<template>
  <div class="">
    <div
      class="relative bg-gradient-to-b from-neutral-500 to-neutral-700 flex flex-col items-center gap-44 p-6 pt-10 h-400 text-white"
    >
      <img
        src="../../assets/aca_img/branch.png"
        class="w-full h-full absolute top-0 right-0 object-cover object-center mix-blend-overlay"
        alt=""
      />

      <h1
        class="text-3xl leading-[3rem] md:text-4xl md:leading-[3.5rem] z-20 text-center mt-40 max-w-2xl"
      >
        تهدف الهيئة إلى تحقيق رقابة إدارية فعالة على الجهاز التنفيذي في الدولة
      </h1>
    </div>

    <div class="px-4 lg:px-12 max-w-container mx-auto">
      <div
        class="bg-white -mt-56 relative py-12 lg:py-24 px-4 lg:px-12 shadow-2xl"
      >
        <div
          class="md:flex gap-8 items-center justify-between xl:justify-start xl:gap-40 px-4 md:px-0 md:pl-8 xl:pl-16"
        >
          <p
            class="text-2xl xl:text-4xl xl:leading-[3rem] border-r-8 border-primary-100 pr-20 py-4 max-w-xs"
          >
           فروع هيئة الرقابة الإدارية
          </p>
          <p
            class="font-medium max-w-2xl xl:max-w-3xl xl:text-xl xl:leading-9 leading-8 mt-6 md:mt-0"
          >
            وريم إيبسوم هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس
            المحتوى) ويُستخدم في صناعات المطابع ودور النشر.
          </p>
        </div>

        <iframe
          class="w-full shadow-2xl mt-20 rounded-md h-[30rem] lg:h-[40rem]"
          src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3351.03767538!2d13.227472315184428!3d32.870722580943806!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMzLCsDUyJzE0LjYiTiAxM8KwMTMnNDYuOCJF!5e0!3m2!1sen!2seg!4v1677750475406!5m2!1sen!2seg"
          width="600"
          height="450"
          style="border: 0"
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>

    <div class="mt-8 px-4 lg:px-12 max-w-container mx-auto">
      <div
        class="shadow-[0_2px_6px_1px_#e7e7e7] rounded-br-md rounded-bl-md pb-8"
      >
        <table class="w-full table-fixed break-words">
          <thead class="lg:text-right text-xl bg-primary-100 text-white">
            <th class="py-5 border-b border-gray-300 hidden md:table-cell text-right pr-4 w-32">
              #
            </th>
            <th
              class="py-5 border-b border-gray-300  xl:w-60"
            >
              الفرع/المكتب
            </th>
            <th class="py-5 border-b border-gray-300 hidden xl:table-cell">
              العنوان
            </th>
            <th class="py-5 border-b border-gray-300 hidden lg:table-cell">
              هاتف
            </th>
            <th class="py-5 border-b border-gray-300 hidden xl:table-cell">
              البريد الإلكتروني
            </th>
            
          </thead>

          <tbody class="text-sm">
            <tr class="border-b border-gray-200 hover:bg-gray-50">
              <td class="hidden md:table-cell pr-4">01</td>
              <td class=" py-4 md:py-7 text-center lg:text-right">
                فرع هيئة الرقابة الادارية بنغازي
              </td>
              <td class="hidden xl:table-cell">بنغازي</td>
              <td class="hidden lg:table-cell">091-1110111</td>
              <td class="hidden xl:table-cell">info@aca.gov.ly</td>
            </tr>
            <tr class="border-b border-gray-200 hover:bg-gray-50">
              <td class="hidden md:table-cell  pr-4">02</td>
              <td class="  py-4 md:py-7  text-center lg:text-right">
                فرع هيئة الرقابة الادارية بنغازي
              </td>
              <td class="hidden xl:table-cell">بنغازي</td>
              <td class="hidden lg:table-cell">091-1110111</td>
              <td class="hidden xl:table-cell">info@aca.gov.ly</td>
              
            </tr>
          </tbody>
        </table>

        <div class="mt-16 flex justify-center items-center gap-6">
          <button class="bg-gray-300 py-2 px-3 rounded">
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 16.4961L8.5 8.99609L1 1.49609"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
          <button class="py-1 px-3 border border-black rounded">1</button>
          <button
            class="py-1 px-3 border border-gray-300 rounded text-gray-300"
          >
            2
          </button>
          <button
            class="py-1 px-3 border border-gray-300 rounded text-gray-300"
          >
            3
          </button>
          <button class="bg-black py-2 px-3 rounded">
            <svg
              width="10"
              height="18"
              viewBox="0 0 10 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.99902 16.4922L1.49902 8.99219L8.99902 1.49219"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scroll(0, 0);
  }
};
</script>

<style></style>
