import * as API from "./API";

import store from "../store";

export default {
    // getMuncipalOrderWithoutPagination(is_approved, id) {
    //     return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`);
    // },

    getMuncipalOrder(page_number, page_size, is_approved, id) {
        return API.apiClient.get(`/api/Order/get_order_for_muncipal?page_number=${page_number}&page_size=${page_size}&is_approved=${is_approved}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMunicipalStorageApproved(page_number, page_size, id) {
        return API.apiClient.get(`/api/Order/get_order_approved?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // getMuncipalOrderById(id) {
    //     return API.apiClient.get(`/api/Order/Order?id=${id}`);
    // },

    createMuncipalOrder(order) {
        return API.apiClient.post(`/api/Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    orderApproval(id) {
        return API.apiClient.get(`/api/Order/change_state_of_order_from_muncipla?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    orderDeliveryTovaccinationCenter(order) {
        return API.apiClient.put(`/api/Order/order_delivery_tovaccination_center`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    OrderReject(order) {
        return API.apiClient.put(`/api/Order/reject_order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getOrderReadyToDelivery(page_number, page_size, id) {
        return API.apiClient.get(`/api/Order/all_orders_Delevery_from_Medical_supplies?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    confirmReceipt(id) {
        return API.apiClient.get(`/api/Order/The_municipality_received?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // updateMuncipalOrder(order) {
    //     return API.apiClient.put(`/api/Order`, order);
    // },

};