import * as API from "./API";

import store from "../store";

export default {
    getMunicipalStorageWithoutPagination(id) {
        return API.apiClient.get(`/api/Medical_supplies_Storage/GetAll?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMunicipalStorage(page_number, page_size, id) {
        return API.apiClient.get(`/api/Municipal_storage/GetAllMedical_supplies_storegs?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMunicipalStorageApproved(page_number, page_size, id) {
        return API.apiClient.get(`/api/Order/get_order_approved?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },




    // getMedicalSupplyStorage(id) {
    //     return API.apiClient.get(`/api/Medical_supplies_Storage/GetByID?id=${id}`);
    // },

    // createMedicalSupplyStorage(medicalsupplystorage) {
    //     return API.apiClient.post(`/api/Medical_supplies_Storage/Add`, medicalsupplystorage);
    // },

    // deleteMedicalSupplyStorage(id) {
    //     return API.apiClient.delete(`/api/Medical_supplies_Storage/Delete?id=${id}`);
    // },

    // updateMedicalSupply(medicalsupply) {
    //     return API.apiClient.put(`/api/Medical_supplies/Update`, medicalsupply);
    // },

};