import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/css/index.css'
import store from "./store";

import './shared/GlobalFilters'

// import CryptoJS from 'crypto-js'

// Vue.use(CryptoJS)

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2);

import Pagination from 'vue-pagination-2';

Vue.component('pagination', Pagination);

import Vuelidate from 'vuelidate'
Vue.use(Vuelidate)

import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

Vue.use(Loading, {
    color: '#000000',
    width: 64,
    height: 64,
    backgroundColor: '#ffffff',
    opacity: 0.5,
    zIndex: 999,
});

Vue.directive("click-outside", {
    bind(el, binding, vnode) {
        el.clickOutsideEvent = (event) => {
            if (!(el === event.target || el.contains(event.target))) {
                vnode.context[binding.expression](event);
            }
        };
        document.body.addEventListener("click", el.clickOutsideEvent);
    },
    unbind(el) {
        document.body.removeEventListener("click", el.clickOutsideEvent);
    },
});

Vue.config.productionTip = false




import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDlKKCHDkN8JKdZHsB8o2oeQxSI0vQJmzg',
        libraries: '3.26', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
})


import axios from "axios";

// axios.defaults.baseURL = 'http://41.208.70.232/';
// axios.defaults.baseURL = 'https://localhost:44384/';

// axios.defaults.headers.common['Authorization'] = `Bearer ${ localStorage.getItem('䫀笢') }`;
// axios.defaults.headers.common['Authorization'] = `Bearer ${ store.state.auth.accessToken }`;



axios.defaults.withCredentials = false

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')