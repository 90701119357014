import * as API from "./API";

import store from "../store";

export default {
    getMedicalSuppliesStorageWithoutPagination(id) {
        return API.apiClient.get(`/api/Medical_supplies_Storage/GetAll?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMedicalSuppliesStorage(page_number, page_size, id) {
        return API.apiClient.get(`/api/Medical_supplies_Storage/GetAllMedical_supplies_storegs?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getMedicalSupplyStorage(id) {
        return API.apiClient.get(`/api/Medical_supplies_Storage/GetByID?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createMedicalSupplyStorage(medicalsupplystorage) {
        return API.apiClient.post(`/api/Medical_supplies_Storage/Add`, medicalsupplystorage, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteMedicalSupplyStorage(id) {
        return API.apiClient.delete(`/api/Medical_supplies_Storage/Delete?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // updateMedicalSupply(medicalsupply) {
    //     return API.apiClient.put(`/api/Medical_supplies/Update`, medicalsupply);
    // },

};