<template>
  <div class="">
    <div class="bg-gray-50 h-56 flex items-center border-t border-gray-200">
      <div
        class="w-full px-4 lg:px-12 max-w-container mx-auto flex flex-col items-center"
      >
        <h2 class="text-4xl">لاتوجد نتائج</h2>
        <p class="flex gap-2 mt-8">
          <span>الرئيسية</span>
          <span>-</span>
          <span class="font-medium"> رؤية الهيئة</span>
        </p>
      </div>
    </div>

    <div
      class="px-4 lg:px-12 max-w-container mx-auto flex gap-y-8 flex-col lg:flex-row justify-around items-center mt-10 md:mt-24"
    >
      <svg
        class="w-60 h-60 md:w-96 md:h-96 xl:w-[582px]"
        width="582"
        height="562"
        viewBox="0 0 582 562"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1_9222)">
          <path
            opacity="0.1"
            d="M199.767 540.7C352.311 596.221 518.579 524.171 571.136 379.77C623.694 235.37 542.639 73.3013 390.094 17.7797C237.55 -37.7419 71.2824 34.3087 18.725 178.709C-33.8325 323.11 47.2228 485.178 199.767 540.7Z"
            fill="url(#paint0_linear_1_9222)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M472.452 130.938H143.944V151.524H84.7598V365.098H462.16V344.511H472.453L472.452 130.938Z"
            fill="#EFDC8E"
          />
          <path
            d="M143.945 130.938H472.454V151.524H143.945V130.938Z"
            fill="white"
            fill-opacity="0.6"
          />
          <path
            d="M472.452 151.524H454.461V179.115H472.452V151.524Z"
            fill="white"
            fill-opacity="0.6"
          />
          <path
            d="M84.7598 151.525L123.269 214.974L142.183 304.142L462.16 365.098H84.7598V151.525Z"
            fill="black"
            fill-opacity="0.04"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M489.178 178.546L303.478 177.259L288.899 200.626H67.1777L72.7527 413.132H477.598L486.985 201.702H487.033L489.178 178.546Z"
            fill="#F2C60D"
          />
          <path
            d="M67.1777 200.621L121.108 365.202H332.408L477.598 413.128H72.7527L67.1777 200.621Z"
            fill="black"
            fill-opacity="0.08"
          />
          <path
            d="M123.359 270.748C123.36 269.383 123.902 268.074 124.867 267.109C125.832 266.144 127.141 265.602 128.506 265.602H251.16C252.525 265.602 253.834 266.144 254.799 267.109C255.764 268.074 256.306 269.383 256.306 270.748C256.306 272.112 255.764 273.421 254.799 274.386C253.834 275.351 252.525 275.894 251.16 275.894H128.506C127.831 275.894 127.161 275.761 126.537 275.502C125.912 275.244 125.345 274.865 124.867 274.387C124.389 273.909 124.01 273.342 123.751 272.717C123.493 272.093 123.359 271.423 123.359 270.748Z"
            fill="#F0F4F8"
          />
          <path
            d="M123.359 287.903C123.359 286.766 123.811 285.675 124.616 284.87C125.42 284.066 126.511 283.614 127.648 283.614H227.148C228.274 283.631 229.349 284.091 230.139 284.893C230.929 285.696 231.372 286.777 231.372 287.903C231.372 289.029 230.929 290.11 230.139 290.912C229.349 291.715 228.274 292.174 227.148 292.191H127.648C126.511 292.191 125.42 291.74 124.616 290.935C123.812 290.131 123.36 289.041 123.359 287.903Z"
            fill="#F0F4F8"
          />
          <path
            opacity="0.7"
            d="M370.402 382.437C385.329 382.436 399.92 378.009 412.331 369.716C424.741 361.422 434.414 349.635 440.126 335.844C445.838 322.054 447.332 306.879 444.419 292.239C441.507 277.599 434.318 264.152 423.763 253.597C413.208 243.042 399.76 235.855 385.12 232.943C370.48 230.031 355.305 231.526 341.515 237.239C327.724 242.951 315.938 252.624 307.645 265.036C299.352 277.447 294.926 292.038 294.926 306.965C294.926 316.877 296.878 326.691 300.671 335.848C304.464 345.005 310.024 353.325 317.032 360.333C324.041 367.342 332.362 372.901 341.519 376.693C350.676 380.486 360.49 382.438 370.402 382.437V382.437Z"
            stroke="#2249B3"
          />
          <path
            d="M488.242 433.59L425.09 358.971C438.447 344.953 445.922 326.348 445.976 306.986C446.031 287.623 438.66 268.977 425.382 254.884C412.104 240.792 393.928 232.326 374.597 231.229C355.265 230.132 336.249 236.488 321.462 248.988C306.675 261.488 297.243 279.182 295.107 298.426C292.971 317.67 298.293 337.001 309.979 352.44C321.665 367.878 338.824 378.25 357.926 381.419C377.027 384.588 396.617 380.314 412.662 369.477L475.826 444.107C476.514 444.931 477.358 445.61 478.31 446.107C479.262 446.603 480.302 446.906 481.371 446.999C482.441 447.091 483.518 446.971 484.541 446.645C485.563 446.32 486.512 445.795 487.331 445.101C488.15 444.407 488.823 443.558 489.313 442.603C489.802 441.648 490.098 440.605 490.183 439.535C490.267 438.465 490.14 437.389 489.807 436.369C489.473 435.348 488.942 434.404 488.242 433.59ZM412.105 356.23C402.36 364.477 390.389 369.65 377.704 371.094C365.019 372.537 352.191 370.187 340.842 364.34C329.493 358.494 320.133 349.413 313.945 338.246C307.756 327.08 305.018 314.329 306.077 301.606C307.135 288.884 311.942 276.761 319.89 266.77C327.839 256.78 338.571 249.37 350.73 245.479C362.889 241.588 375.929 241.39 388.201 244.909C400.472 248.429 411.425 255.508 419.673 265.253C425.152 271.723 429.303 279.209 431.888 287.283C434.473 295.358 435.442 303.863 434.739 312.312C434.037 320.761 431.676 328.989 427.792 336.525C423.909 344.062 418.578 350.759 412.105 356.235V356.23Z"
            fill="#102A43"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M364.115 335.464C364.115 333.672 364.647 331.92 365.642 330.43C366.638 328.94 368.053 327.779 369.709 327.093C371.364 326.407 373.186 326.228 374.944 326.577C376.702 326.927 378.316 327.79 379.583 329.057C380.851 330.324 381.714 331.939 382.063 333.697C382.413 335.454 382.233 337.276 381.548 338.932C380.862 340.588 379.7 342.003 378.21 342.998C376.72 343.994 374.968 344.525 373.176 344.525C370.773 344.525 368.468 343.571 366.769 341.871C365.07 340.172 364.115 337.867 364.115 335.464V335.464Z"
            fill="#F0F4F8"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M379.186 308.881C378.461 316.134 367.886 316.134 367.165 308.881L364.145 278.676C364.061 277.836 364.154 276.988 364.418 276.187C364.682 275.385 365.111 274.648 365.677 274.022C366.243 273.396 366.934 272.896 367.705 272.554C368.477 272.211 369.311 272.034 370.155 272.034H376.196C377.04 272.034 377.875 272.211 378.646 272.553C379.418 272.896 380.109 273.396 380.675 274.022C381.242 274.647 381.671 275.385 381.934 276.187C382.198 276.988 382.291 277.836 382.207 278.676L379.186 308.881Z"
            fill="#F0F4F8"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1_9222"
            x1="555.692"
            y1="178.133"
            x2="72.548"
            y2="547.109"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#486581" />
            <stop offset="1" stop-color="#486581" stop-opacity="0" />
          </linearGradient>
          <clipPath id="clip0_1_9222">
            <rect width="581.86" height="561.48" fill="white" />
          </clipPath>
        </defs>
      </svg>
      <div class="text-center lg:text-right">
        <p class="text-4xl">لا توجد نتائج!</p>
        <p class="font-medium mt-8">
          نأسف، لاتوجد نتيجة مطابقة لبحثك، أعد المحاولة بكلمات أخرى.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scroll(0, 0);
  }
};
</script>

<style></style>
