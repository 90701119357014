<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-8">


                <div class="card card-default">
                    <div class="card-header">Register</div>
                    <div class="card-body">
                        <form>
                            <div class="form-group row">
                                <label for="name" class="col-sm-4 col-form-label text-md-right">Username</label>
                                <div class="col-md-6">
                                    <input id="name" type="text" class="form-control" v-model="name" required
                                            autofocus autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="email" class="col-sm-4 col-form-label text-md-right">E-Mail Address</label>
                                <div class="col-md-6">
                                    <input id="email" type="email" class="form-control" v-model="email" required
                                            autofocus autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Password</label>
                                <div class="col-md-6">
                                    <input id="password" type="password" class="form-control" v-model="password"
                                            required autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row">
                                <label for="password" class="col-md-4 col-form-label text-md-right">Confirm Password</label>
                                <div class="col-md-6">
                                    <input id="password_confirmation" type="password" class="form-control" v-model="password_confirmation"
                                            required autocomplete="off">
                                </div>
                            </div>

                            <div class="form-group row mb-0">
                                <div class="col-md-8 offset-md-4">
                                    <button type="submit" class="btn btn-primary" @click="registerUser">
                                        Register
                                    </button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import AuthService from "@/services/AuthService";

export default {
    components: {
    },
    data() {
        return {
            name: "",
            email: "",
            password: "",
            password_confirmation: "",
            error: null
        }
    },
    methods: {

        registerUser(e) {
            // let that = this;
            this.error = null;
            e.preventDefault()

            const payload = {
                name: this.name,
                email: this.email,
                password: this.password,
                password_confirmation: this.password_confirmation,
            };
            AuthService.registerUser(payload)
                .then(() => this.$router.push("/login"))
                .catch(
                    (error) => {
                        console.log(error);
                        // this.error = getError(error);
                    }
                    );
                // that.$swal("حدث خطأ غير متوقع"),
        },

        // handleSubmit(e) {
        //     e.preventDefault()
        //     if (this.password.length > 0) {
        //         axios.get('/sanctum/csrf-cookie').then(response => {
        //             axios.post('api/v1/users/auth/signup', {
        //                 name: this.name,
        //                 email: this.email,
        //                 password: this.password,
        //                 password_confirmation: this.password_confirmation
        //             })
        //                 .then(response => {
        //                     if (response.data.success) {
        //                         window.location.href = "/login"
        //                     } else {
        //                         that.$swal(response.data.message);
        //                     }
        //                 })
        //                 .catch(function (err) {
        //                     that.$swal("حدث خطأ غير متوقع");
        //                     console.log(err.response.message);
        //                 });
        //         })
        //     }
        // }
    },
}
</script>
