<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
                تعديل بيانات البلدية
            </h1>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
         
            <errormessage :error="errors"></errormessage>
            <form @submit.prevent="updateHealthCenterOrder" class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4  sm:p-6 shadow sm:rounded-md sm:overflow-hidden">
                <div class="grid md:grid-cols-4 gap-8">
                    
                    <div class="input" >
                        <label for="vaccine_id" class="block text-sm font-medium ">
                            اختر القاح 
                        </label>
                        <select
                            id="vaccine_id"
                            name="vaccine_id"
                            v-model="medical_supply_to_order.vaccine_id"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>القاح</option>
                            <option
                                v-for="vaccine in vaccines_data"
                                :key="vaccine.id"
                                v-bind:value="vaccine.id"
                            >
                                {{ vaccine.name_en }}
                            </option>

                        </select>
                    </div>


                    <div class="input" >
                        <label for="Center_id" class="block text-sm font-medium ">
                            اختر المركز 
                        </label>
                        <select
                            id="Center_id"
                            name="Center_id"
                            v-model="medical_supply_to_order.health_Center_id"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>المراكز</option>
                            <option
                                v-for="center in vaccination_centers_data"
                                :key="center.id"
                                v-bind:value="center.id"
                            >
                                {{ center.name }}
                            </option>

                        </select>
                    </div>
       
                </div>

                <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
                    <div class="inline w-full md:w-auto" :class=" $v.$anyError ? 'cursor-not-allowed' : 'cursor-pointer' ">
                        <button
                            :disabled="$v.$anyError"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$anyError ? 'bg-gray-600' : 'bg-indigo-600 hover:bg-indigo-700 focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500'">
                            تعديل
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>
import errormessage from "@/components/ErrorMessage"
import { required } from "vuelidate/lib/validators";
import VaccinationCenterOrdersService from "@/services/VaccinationCenterOrdersService";

import VaccinesService from "@/services/VaccinesService";

import VaccinationCentersService from "@/services/VaccinationCentersService";

export default {
    created() {
        this.getVaccinationCenters();
        this.getVaccines();
        this.get_health_center_order();
    },

    components: {
        errormessage
    },

    data() {
        return {
            errors: null,
            vaccines_data:{},

            medical_supply_to_order: {
                id: this.$route.params.order,
                vaccine_id : '',
                health_Center_id : this.$route.params.id,
                order_state: 1,
            },

            municipal_id: this.$store.state.auth.role.guid,
            vaccination_centers_data: {},

        };
    },

    validations: {
        medical_supply_to_order: {
            vaccine_id: {
                required,
            },
        },
    },

    methods: {

        getVaccinationCenters() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            VaccinationCentersService.getVaccinationCentersByMunicipalWithoutPagination(
                this.municipal_id
            )
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();
                        this.vaccination_centers_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        getVaccines() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            VaccinesService.getVaccinesWithoutPagination()
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.vaccines_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        get_health_center_order() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            VaccinationCenterOrdersService.getHealthCenterOrderById(this.$route.params.order)
                .then((res) => {
                    setTimeout(() => {
                        let data = res.data
                        this.medical_supply_to_order = data
                        loader.hide();
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },
       
        updateHealthCenterOrder() {

            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                id: this.$route.params.order,
                order_state : 1,
                vaccine_id: this.medical_supply_to_order.vaccine_id,
                health_Center_id: this.medical_supply_to_order.health_Center_id,
                order_by: 3
            }

            VaccinationCenterOrdersService.updateHealthCenterOrder(data)
                .then((resp) => {

                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.$router.push({ name: "medicalSuppliesOrder", params: { id: this.$route.params.id }  });
                    }, 10);
                
                },
                (err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.message,
                    })
                }
            );
        },
    },
};
</script>

