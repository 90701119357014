<template>
    <div class="">
        <div class="">
            <h1 class="text-2xl font-semibold text-slate-900 dark:text-white">لوحة التحكم</h1>
        </div>
        <div class="">
            <!-- Replace with your content -->
            <div class="py-4">
                <div dir="ltr"
                    class=" rounded-lg border-4 border-dashed border-gray-200"
                >
                    <!-- border-4 border-dashed border-gray-200 -->
                    <!-- <img src="../assets/img/c2.png" alt="" class="hidden dark:block"> 
                    <img src="../assets/img/c3.png" alt="" class="dark:hidden ">  -->
                    <p>
                        administrator : {{ administrator }}
                    </p>
                    <br><br>
                    <p>
                        role : {{ role }}
                    </p>
                    <br><br>
                    <p>
                        loginError : {{ loginError }}
                    </p>

                    <br><br>
                    <p>
                        loggingIn : {{ loggingIn }}
                    </p>
                </div>
            </div>
            <!-- /End replace -->
        </div>
    </div>
</template>

<script>
export default {
    name: "Dashboard",
    data() {
        return {
            accessToken: this.$store.state.auth.accessToken,
            administrator: this.$store.state.auth.administrator,
            role: this.$store.state.auth.role,
            loginError: this.$store.state.auth.loginError,
            loggingIn: this.$store.state.auth.loggingIn,
        };
    },
    created() {
       
    },
};
</script>
