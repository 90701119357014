<template>
  <div class="pb-24">
    <div
      class="bg-[url('assets/images/Group.png')] bg-gray-50 h-56 flex items-center border-t border-gray-200"
    >
      <div class="w-full px-4 lg:px-12 max-w-container mx-auto">
        <h2 class="text-4xl">تقديم شكوى / بلاغ</h2>
        <p class="flex gap-2 mt-8">
          <span>الرئيسية</span>
          <span>-</span>
          <span class="font-medium"> تقديم شكوى / بلاغ </span>
        </p>
      </div>
    </div>

    <div
      class="px-4 lg:px-12 max-w-container mx-auto lg:flex gap-8 mt-10 md:mt-24 text-center"
    >
      <div
        class="lg:w-1/2 shadow-complaint_report px-6 md:px-16 py-16 flex flex-col items-center gap-12 rounded-2xl"
      >
        <svg
          class="w-44 h-44 sm:w-72"
          width="196"
          height="229"
          viewBox="0 0 196 229"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g clip-path="url(#clip0_1_7859)">
            <path
              d="M195.999 49.2781C195.999 39.7123 188.217 31.8857 178.705 31.8857H48.9992C39.4874 31.8857 31.7051 39.7123 31.7051 49.2781V211.607C31.7051 221.173 39.4874 229 48.9992 229H146.999L195.999 182.62V49.2781Z"
              fill="#F7F7F7"
            />
            <path
              d="M31.7059 49.2785C31.7059 39.7127 39.4882 31.8861 49 31.8861H164.294V17.3924C164.294 7.82658 156.512 0 147 0H17.2941C7.78235 0 0 7.82658 0 17.3924V179.722C0 189.287 7.78235 197.114 17.2941 197.114H31.7059V49.2785Z"
              fill="#F2C60D"
            />
            <path
              d="M164.294 182.62H196L147 229V200.013C147 190.447 154.782 182.62 164.294 182.62Z"
              fill="#DDDDDD"
            />
            <path
              d="M74.9407 108.702H152.764C155.157 108.702 157.088 106.76 157.088 104.354C157.088 101.948 155.157 100.006 152.764 100.006H74.9407C72.5484 100.006 70.6172 101.948 70.6172 104.354C70.6172 106.76 72.5484 108.702 74.9407 108.702Z"
              fill="black"
            />
            <path
              d="M74.9407 79.7148H152.764C155.157 79.7148 157.088 77.7726 157.088 75.3667C157.088 72.9607 155.157 71.0186 152.764 71.0186H74.9407C72.5484 71.0186 70.6172 72.9607 70.6172 75.3667C70.6172 77.7726 72.5484 79.7148 74.9407 79.7148Z"
              fill="black"
            />
            <path
              d="M74.9407 137.69H152.764C155.157 137.69 157.088 135.748 157.088 133.342C157.088 130.936 155.157 128.994 152.764 128.994H74.9407C72.5484 128.994 70.6172 130.936 70.6172 133.342C70.6172 135.748 72.5484 137.69 74.9407 137.69Z"
              fill="black"
            />
            <path
              d="M74.9407 166.678H118.176C120.568 166.678 122.5 164.735 122.5 162.33C122.5 159.924 120.568 157.981 118.176 157.981H74.9407C72.5484 157.981 70.6172 159.924 70.6172 162.33C70.6172 164.735 72.5484 166.678 74.9407 166.678Z"
              fill="black"
            />
          </g>
          <defs>
            <clipPath id="clip0_1_7859">
              <rect width="196" height="229" fill="white" />
            </clipPath>
          </defs>
        </svg>
        <h3>طلب تقديم شكوى</h3>
        <p class="text-gray-400 leading-8 max-w-md">
          وريم إيبسوم هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى)
          ويُستخدم في صناعات المطابع ودور النشر.
        </p>

        <router-link
          :to="{ name: 'complaint' }"
          class="group bg-secondary-100 text-white w-fit rounded py-2 md:py-3 px-4 md:px-6 flex gap-2 items-center hover:bg-white hover:text-secondary-200 border-2 border-secondary-200 duration-500 cursor-pointer"
        >
          <span> تعبيئة النموذج </span>
          <svg
            class="text-white stroke-current group-hover:text-secondary-200"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 12L6 8L10 4"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
      </div>
      <div
        class="lg:w-1/2 shadow-complaint_report px-6 md:px-16 py-16 flex flex-col items-center gap-12 rounded-2xl mt-8 lg:mt-0"
      >
        <svg
          class="w-44 h-44 sm:w-72"
          width="277"
          height="234"
          viewBox="0 0 277 234"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M68.7693 71.3516H22.7116C11.8552 71.3516 2.97266 80.2457 2.97266 91.1163V176.764C2.97266 187.634 11.8552 196.529 22.7116 196.529H42.4506V229.47L81.9286 196.529H154.305C165.161 196.529 174.044 187.634 174.044 176.764V117.469"
            stroke="black"
            stroke-width="4"
          />
          <path
            d="M256.29 0H88.5085C77.6521 0 68.7695 8.89415 68.7695 19.7648V128.471C68.7695 139.342 77.6521 148.236 88.5085 148.236H190.493L236.551 187.765V148.236H256.29C267.146 148.236 276.029 139.342 276.029 128.471V19.7648C276.029 8.89415 267.146 0 256.29 0Z"
            fill="#F2C60D"
          />
          <path
            d="M180.749 119.084C178.749 120.94 176.25 122 173.249 122C170.499 122 167.998 120.94 165.999 119.084C163.999 117.228 163 114.313 163 110.867C163 107.686 163.999 105.299 165.999 103.18C168 101.06 170.499 100 173.5 100C176.499 100 179 101.06 181.001 103.18C183.001 105.301 184 107.951 184 110.867C184 114.314 183.001 116.963 180.749 119.084H180.749Z"
            fill="black"
          />
          <path
            d="M183.498 46.3192L181.249 80.5974C180.748 84.9786 180.249 88.3298 179.248 90.6489C178.249 92.711 176.499 94 173.998 94C171.497 94 169.748 92.9698 168.747 90.6489C167.748 88.3298 166.997 85.2357 166.747 80.8547L163.5 45.2874C163.251 41.9363 163 38.8441 163 35.7518C163 30.3385 163.75 26.2162 165 23.3812C166.25 20.5461 169 19 173 19C176.251 19 179 20.0301 181.001 22.3511C183.001 24.6702 184 27.7643 184 31.6294C183.998 34.7216 183.749 39.6186 183.498 46.3191L183.498 46.3192Z"
            fill="black"
          />
        </svg>

        <h3>تقديم بلاغ</h3>
        <p class="text-gray-400 leading-8 max-w-md">
          وريم إيبسوم هو ببساطة نص شكلي (بمعنى أن الغاية هي الشكل وليس المحتوى)
          ويُستخدم في صناعات المطابع ودور النشر.
        </p>

        <router-link
          :to="{ name: 'report_form' }"
          class="group bg-secondary-100 text-white w-fit rounded py-2 md:py-3 px-4 md:px-6 flex gap-2 items-center hover:bg-white hover:text-secondary-200 border-2 border-secondary-200 duration-500 cursor-pointer"
        >
          <span> تعبيئة النموذج </span>
          <svg
            class="text-white stroke-current group-hover:text-secondary-200"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10 12L6 8L10 4"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    window.scroll(0, 0);
  }
};
</script>

<style></style>
