import * as API from "./API";

import store from "../store";

export default {

    getSpoiledVaccineForVaccinationCenters(page_number, page_size, id) {
        return API.apiClient.get(`/api/spoiled_vaccines_Health_center_Storages?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createSpoiledVaccineForVaccinationCenters(spoiled) {
        return API.apiClient.post(`/api/spoiled_vaccines_Health_center_Storages`, spoiled, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateSpoiledVaccineForVaccinationCenters(spoiled) {
        return API.apiClient.put(`/api/spoiled_vaccines_Health_center_Storages`, spoiled, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },







    getSpoiledVaccineForMedicalSupplies(page_number, page_size, id) {
        return API.apiClient.get(`/api/Spoiled_Medical_Supplies?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createSpoiledVaccineForMedicalSupplies(spoiled) {
        return API.apiClient.post(`/api/Spoiled_Medical_Supplies`, spoiled, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateSpoiledVaccineForMedicalSupplies(spoiled) {
        return API.apiClient.put(`/api/Spoiled_Medical_Supplies`, spoiled, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },





    getSpoiledVaccineForMuncipal(page_number, page_size, id) {
        return API.apiClient.get(`/api/Spoiled_Muncipal_Storeges?page_number=${page_number}&page_size=${page_size}&id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createSpoiledVaccineForMuncipal(spoiled) {
        return API.apiClient.post(`/api/Spoiled_Muncipal_Storeges`, spoiled, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateSpoiledVaccineForMuncipal(spoiled) {
        return API.apiClient.put(`/api/Spoiled_Muncipal_Storeges`, spoiled, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    // getSpoiledVaccineWithoutPagination(is_approved, id) {
    //     return API.apiClient.get(`/api/spoiled/get_spoileds_for_health_center?is_approved=${is_approved}&id=${id}`);
    // },



    // getSpoiledVaccineById(id) {
    //     return API.apiClient.get(`/api/spoiled/spoiled?id=${id}`);
    // },





    // updateSpoiledVaccine(spoiled) {
    //     return API.apiClient.put(`/api/spoiled`, spoiled);
    // },

};