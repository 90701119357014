import * as API from "./API";

import store from "../store";

export default {
    getVaccinationCentersWithoutPagination() {
        return API.apiClient.get(`/api/HealthCenter/GetAll`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccinationCentersByMunicipal(page_number, page_size, Municipalid) {
        return API.apiClient.get(`/api/HealthCenter/GetAllHealthCentersByid?page_number=${page_number}&page_size=${page_size}&Municipalid=${Municipalid}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccinationCentersByMunicipalWithoutPagination(Municipalid) {
        return API.apiClient.get(`/api/HealthCenter/GetAllById?Municipalid=${Municipalid}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccinationCenters(page_number, page_size) {
        return API.apiClient.get(`/api/HealthCenter/GetAllHealthCenters?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getVaccinationCenter(id) {
        return API.apiClient.get(`/api/HealthCenter/GetByID?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    createVaccinationCenter(vaccinationcenter) {
        return API.apiClient.post(`/api/HealthCenter/Add`, vaccinationcenter, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    deleteVaccinationCenter(id) {
        return API.apiClient.delete(`/api/HealthCenter/Delete?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateVaccinationCenter(vaccinationcenter) {
        return API.apiClient.put(`/api/HealthCenter/Update`, vaccinationcenter, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

};