import * as API from "./API";

import store from "../store";

export default {
    getObtainedVaccineseWithoutPagination(id) {
        return API.apiClient.get(`/api/Obtained_Vaccinese/get_obtained_vaccines_by_citizen_id?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    getObtainedVaccinese(page_number, page_size, registration_number) {
        return API.apiClient.get(`/api/Obtained_Vaccinese/get_all_by_registration_number?page_number=${page_number}&page_size=${page_size}&registration_number=${registration_number}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    getTheAgeGroupsForTheVaccine(id) {
        return API.apiClient.get(`/api/fixed_vaccines/get_fixed_vaccines_by_id?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // getMedicalSuppliesStorage(page_number, page_size, id) {
    //     return API.apiClient.get(`/api/Medical_supplies_Storage/GetAllMedical_supplies_storegs?page_number=${page_number}&page_size=${page_size}&id=${id}`);
    // },

    // getMedicalSupplyStorage(id) {
    //     return API.apiClient.get(`/api/Medical_supplies_Storage/GetByID?id=${id}`);
    // },

    givingVaccine(giving) {
        return API.apiClient.post(`/api/Obtained_Vaccinese`, giving, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    updateGivingVaccine(giving) {
        return API.apiClient.put(`/api/Obtained_Vaccinese`, giving, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    // deleteMedicalSupplyStorage(id) {
    //     return API.apiClient.delete(`/api/Medical_supplies_Storage/Delete?id=${id}`);
    // },



};