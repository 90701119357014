<template>
  <header class="mb-24 lg:mb-0">
    <div class="hidden lg:block bg-secondary-100">
      <div
        class="text-white flex items-center gap-6 py-3 px-4 lg:px-12 max-w-container mx-auto"
      >
        <a href="https://www.facebook.com/people/%D9%87%D9%8A%D8%A6%D8%A9-%D8%A7%D9%84%D8%B1%D9%82%D8%A7%D8%A8%D8%A9-%D8%A7%D9%84%D8%A7%D8%AF%D8%A7%D8%B1%D9%8A%D8%A9-%D8%AF%D9%88%D9%84%D8%A9-%D9%84%D9%8A%D8%A8%D9%8A%D8%A7/100069298331738/" target="_blank">

        
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M9.99936 0.616211C7.4767 0.634263 5.05249 1.63272 3.20644 3.414C1.36039 5.19529 0.227013 7.62961 0.0305864 10.2352C-0.16584 12.8409 0.588997 15.428 2.14573 17.4846C3.70246 19.5412 5.94765 20.9175 8.43701 21.3411V14.057H5.89645V11.0429H8.43701V8.74588C8.43701 6.13109 9.93068 4.68933 12.2145 4.68933C12.9651 4.7002 13.714 4.76808 14.455 4.89244V7.45541H13.1881C12.9725 7.42693 12.7535 7.44898 12.5472 7.51995C12.341 7.59091 12.1527 7.70897 11.9964 7.86541C11.8401 8.02185 11.7197 8.21266 11.6441 8.42377C11.5685 8.63487 11.5397 8.86087 11.5597 9.08507V11.0401H14.3336L13.8902 14.0542H11.563V21.3411C14.0525 20.9174 16.2978 19.5411 17.8545 17.4843C19.4112 15.4275 20.166 12.8403 19.9694 10.2346C19.7728 7.62883 18.6391 5.1945 16.7928 3.41333C14.9466 1.63215 12.5221 0.633932 9.99936 0.616211Z"
            fill="white"
          />
        </svg>
      </a>
        <svg
          width="20"
          height="22"
          viewBox="0 0 20 22"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M17.9455 5.78122C17.9586 6.00834 17.9586 6.2346 17.9586 6.45999C17.9586 13.3746 13.8338 21.341 6.29586 21.341C4.05901 21.3471 1.86992 20.531 0 18.9938C0.32881 19.0398 0.6595 19.0614 0.990345 19.0586C2.84076 19.062 4.63653 18.2734 6.08 16.8237C5.21817 16.7978 4.38457 16.4339 3.69674 15.7831C3.00891 15.1324 2.50155 14.2277 2.24621 13.1967C2.50237 13.248 2.76128 13.2748 3.02069 13.277C3.38527 13.2755 3.7482 13.2157 4.10069 13.0991C3.1646 12.8482 2.32635 12.2004 1.72751 11.2652C1.12867 10.33 0.805926 9.16472 0.813793 7.96606V7.90129C1.38011 8.30443 2.0153 8.53185 2.66621 8.56451C1.79332 7.80965 1.17845 6.67208 0.943221 5.37681C0.707987 4.08154 0.869517 2.7229 1.39586 1.56959C2.41488 3.17949 3.69532 4.50005 5.15244 5.44389C6.60956 6.38774 8.21015 6.93335 9.84828 7.04463C9.78248 6.65033 9.74828 6.2488 9.74621 5.84599C9.74111 5.16555 9.8431 4.49052 10.0464 3.85943C10.2496 3.22835 10.5502 2.65358 10.9308 2.16795C11.3115 1.68231 11.7648 1.29532 12.2649 1.02907C12.765 0.762824 13.3021 0.622534 13.8455 0.616213C14.4092 0.615598 14.9667 0.762199 15.4826 1.04665C15.9984 1.3311 16.4612 1.74715 16.8414 2.26822C17.7609 2.04098 18.6414 1.61335 19.4428 1.00482C19.1408 2.2073 18.5004 3.23162 17.6407 3.88742C18.4554 3.76833 19.2509 3.49492 20 3.07652C19.4416 4.11645 18.747 5.03086 17.9455 5.78122Z"
            fill="white"
          />
        </svg>
        <svg
          width="20"
          height="18"
          viewBox="0 0 20 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.5914 3.30572C19.4776 2.80409 19.2552 2.34663 18.9463 1.97886C18.6375 1.61109 18.2529 1.34585 17.8308 1.20955C16.2666 0.696416 10.0067 0.688485 10.0067 0.688485C10.0067 0.688485 3.7489 0.680553 2.18194 1.16434C1.7598 1.30729 1.37573 1.57689 1.06669 1.9472C0.757645 2.31751 0.534031 2.77606 0.41736 3.27875C0.132464 5.16316 -0.00698454 7.07454 0.000750379 8.98907C-0.0114193 10.9033 0.124461 12.8147 0.406678 14.6994C0.52072 15.2008 0.743318 15.658 1.05232 16.0255C1.36132 16.393 1.74595 16.6579 2.16792 16.794C3.7489 17.3047 9.99137 17.3127 9.99137 17.3127C9.99137 17.3127 16.2512 17.3206 17.8162 16.8368C18.2382 16.7007 18.6231 16.4361 18.9326 16.0691C19.2422 15.7022 19.4659 15.2457 19.5814 14.7446C19.867 12.8603 20.0064 10.9488 19.998 9.03428C20.0184 7.11365 19.8822 5.19519 19.5914 3.30572ZM8.00446 12.5636L8.00914 5.44233L13.2121 9.01128L8.00446 12.5636Z"
            fill="white"
          />
        </svg>

        <!-- <span class="mr-auto">English</span>
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M2 12H22"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 2C14.5013 4.73835 15.9228 8.29203 16 12C15.9228 15.708 14.5013 19.2616 12 22C9.49872 19.2616 8.07725 15.708 8 12C8.07725 8.29203 9.49872 4.73835 12 2V2Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg> -->
      </div>
    </div>

    <div class="bg-[url('../assets/aca_img/Group.png')] bg-left bg-no-repeat pt-7 lg:pt-4  hidden lg:block">
      <nav class="px-4 lg:px-12 max-w-container mx-auto flex items-center lg:items-start gap-12 border-b border-gray-200 relative pb-8 lg:pb-0">
        <div>
          <img class="w-32 lg:w-52" src="../assets/aca_img/logo.png" alt="" />
        </div>

        <div
         
          class="text-sm flex px-10 lg:px-0 flex-col gap-10 lg:gap-0 lg:flex-row z-40 lg:justify-between items-center lg:w-full bg-white lg:bg-transparent shadow-[0_0_8px_0px_grey] lg:shadow-none py-12 lg:py-0 fixed lg:static left-0 top-0 bottom-0 right-[30%] md:right-[65%] -translate-x-full lg:translate-x-0 mt-12 transition-all duration-300"
          id="nav"
        >
          <router-link
            :to="{ name: 'home' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/home')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            الرئيسية
          </router-link>

          <router-link
            :to="{ name: 'about' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/about')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            رؤية الهيئة
          </router-link>

          <router-link
            :to="{ name: 'branches' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/branches')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            فروع الهيئة
          </router-link>

          <router-link
            :to="{ name: 'news' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/news')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            الأخبار
          </router-link>
          <router-link
            :to="{ name: 'research_studies' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/research_studies')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            بحوث ودراسات
          </router-link>

          <router-link
            :to="{ name: 'legislation' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/legislation')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            التشريعات
          </router-link>
          <router-link
            :to="{ name: 'decisions' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/decisions')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            القرارات
          </router-link>
          <!-- <router-link
            :to="{ name: 'about' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/about')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            الشكاوي والبلاغات
          </router-link> -->

          <router-link
            :to="{ name: 'complaint_report' }"
            class="bg-primary-200 py-2 px-8 rounded-md lg:self-start -mt-2"
          >
            تقديم شكوى/ بلاغ
          </router-link>
        </div>
       

        <button @click="nav = !nav" class="lg:hidden  fixed left-4 sm:left-8 z-40 bg-white px-2" id="nav-btn">
          <svg
            class="w-8 lg:w-12"
            width="117"
            height="68"
            viewBox="0 0 117 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="5"
              x2="117"
              y2="5"
              stroke="black"
              stroke-width="10"
            />
            <path d="M1 63H35.5" stroke="black" stroke-width="10" />
            <line
              x1="0.982422"
              y1="34"
              x2="59.0169"
              y2="34"
              stroke="black"
              stroke-width="10"
            />
          </svg>
        </button>
      </nav>
    </div>









    <div class="  fixed top-0 z-50 bg-white w-full  lg:hidden ">
      <nav class="px-4 max-w-container mx-auto flex items-center gap-8 border-b border-gray-200 relative py-4">
        <div>
          <img class="w-24 md:w-32 " src="../assets/aca_img/logo.png" alt="" />
        </div>

        <div
          v-if="nav"
          class="text-sm flex px-10 flex-col gap-10  z-40  items-center  bg-white  shadow-[0_0_8px_0px_grey]  pt-20  fixed  left-0 top-0 bottom-0 right-[30%] md:right-[65%] -translate-x-full  mt-12 transition-all duration-300"
          id="nav"
        >
          <router-link
            :to="{ name: 'home' }"
            class="h-4 md:h-8"
            :class="
              this.checkRoutePath.includes('/home')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            الرئيسية
          </router-link>

          <router-link
            :to="{ name: 'about' }"
            class="h-4 md:h-8"
            :class="
              this.checkRoutePath.includes('/about')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            رؤية الهيئة
          </router-link>

          <router-link
            :to="{ name: 'branches' }"
            class="h-4 md:h-8"
            :class="
              this.checkRoutePath.includes('/branches')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            فروع الهيئة
          </router-link>

          <router-link
            :to="{ name: 'news' }"
            class="h-4 md:h-8"
            :class="
              this.checkRoutePath.includes('/news')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            الأخبار
          </router-link>
          <router-link
            :to="{ name: 'research_studies' }"
            class="h-4 md:h-8"
            :class="
              this.checkRoutePath.includes('/research_studies')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            بحوث ودراسات
          </router-link>

          <router-link
            :to="{ name: 'legislation' }"
            class="h-4 md:h-8"
            :class="
              this.checkRoutePath.includes('/legislation')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            التشريعات
          </router-link>
          <router-link
            :to="{ name: 'decisions' }"
            class="h-4 md:h-8"
            :class="
              this.checkRoutePath.includes('/decisions')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            القرارات
          </router-link>
          <!-- <router-link
            :to="{ name: 'about' }"
            class="h-20"
            :class="
              this.checkRoutePath.includes('/about')
                ? 'border-b-4 border-primary-200'
                : ''
            "
          >
            الشكاوي والبلاغات
          </router-link> -->

          <router-link
            :to="{ name: 'complaint_report' }"
            class="bg-primary-200 py-2 px-8 rounded-md lg:self-start -mt-2"
          >
            تقديم شكوى/ بلاغ
          </router-link>
        </div>
        


        

        <button @click="nav = !nav" class="lg:hidden  fixed left-4 sm:left-8 z-40 bg-white px-2" id="nav-btn">
          <svg
            class="w-8 lg:w-12"
            width="117"
            height="68"
            viewBox="0 0 117 68"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <line
              x1="1"
              y1="5"
              x2="117"
              y2="5"
              stroke="black"
              stroke-width="10"
            />
            <path d="M1 63H35.5" stroke="black" stroke-width="10" />
            <line
              x1="0.982422"
              y1="34"
              x2="59.0169"
              y2="34"
              stroke="black"
              stroke-width="10"
            />
          </svg>
        </button>
      </nav>
    </div>






  </header>
</template>

<script>
export default {
  
  computed: {
    checkRoutePath: function () {
      return this.$route.path;
    },
  },

  data() {
    return {
      nav: false,
    };
  },

};
</script>

<style></style>
