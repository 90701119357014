<template>
  <div class="">
   

    <div class="py-2 align-middle inline-block min-w-full mt-6">

 

      
      


      <div class="grid grid-cols-2 gap-4 ">
        <div class="col-span-2">
          
          <GmapMap
            class="mt-4"
            :center="{ lat: 27, lng: 17 }"
            :zoom="5.2"
            
            map-type-id="terrain"
            style="height: 900px"
          >
            <GmapMarker
              class="relative"
              :key="index"
              v-for="(m, index) in markers_location_of_health_center"
              :icon="require('@../../../src/assets/health_center.png')"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="openInfoWindow(m, 1)"
            >
              <div class="bg-red-600 w-24 h-24">
                {{ openedMarkerID }}sssssssssss
              </div>
            </GmapMarker>

            <gmap-info-window
              v-if="selected_location_health_center !== null"
              :position="{
                lat: selected_location_health_center.position.lat,
                lng: selected_location_health_center.position.lng,
              }"
              :opened="info_box_open_health_center"
              @closeclick="closeInfoWindow(1)"
            >
              <div
                class="infoWindow text-lg font-bold text-black text-center px-2 mt-4 w-full"
              >
                <h2 id="infoWindow-location">
                  {{ selected_location_health_center.name }}
                </h2>
              </div>
            </gmap-info-window>






            <GmapMarker
              class="relative"
              :key="'i'+index"
              v-for="(m, index) in markers_location_of_muncipal"
              :position="m.position"
              :icon="require('@../../../src/assets/muncipal.png')"
              :clickable="true"
              :draggable="false"
              @click="openInfoWindow(m, 2)"
            >
              <div class="bg-red-600 w-24 h-24">
                {{ openedMarkerID }}
              </div>
            </GmapMarker>

            <gmap-info-window
              v-if="selected_location_muncipal !== null"
              :position="{
                lat: selected_location_muncipal.position.lat,
                lng: selected_location_muncipal.position.lng,
              }"
              :opened="info_box_open_muncipal"
              @closeclick="closeInfoWindow(2)"
            >
              <div
                class="infoWindow text-lg font-bold text-black text-center px-2 mt-4 w-full"
              >
                <h2 id="infoWindow-location">
                  {{ selected_location_muncipal.name }}
                </h2>
              </div>
            </gmap-info-window>













            <GmapMarker
              class="relative"
              :key="index"
              v-for="(m, index) in markers_location_medical_supplies"
              :position="m.position"
              :icon="require('@../../../src/assets/medical_supplies.png')"
              :clickable="true"
              :draggable="false"
              @click="openInfoWindow(m, 3)"
            >
              <div class="bg-red-600 w-24 h-24">
                {{ openedMarkerID }}
              </div>
            </GmapMarker>

            <gmap-info-window
              v-if="selected_location_medical_supplies !== null"
              :position="{
                lat: selected_location_medical_supplies.position.lat,
                lng: selected_location_medical_supplies.position.lng,
              }"
              :opened="info_box_open_medical_supplies"
              @closeclick="closeInfoWindow(3)"
            >
              <div
                class="infoWindow text-lg font-bold text-black text-center px-2 mt-4 w-full"
              >
                <h2 id="infoWindow-location">
                  {{ selected_location_medical_supplies.name }}
                </h2>
              </div>
            </gmap-info-window>




            <!-- 
                    :icon="require('@/assets/images/place-icon.svg').default"
                 -->
          </GmapMap>
        </div>


      </div>


    </div>
  </div>
</template>

<script>
import Dashboard from "@/services/DashboardService";



export default {
  components: {
   
  },

  data() {
    return {
      openedMarkerID: null,
      center: { lat: 51.093048, lng: 6.84212 },
      markers_location_of_health_center: [],
      markers_location_of_muncipal: [],
      markers_location_medical_supplies: [],

      selected_location_health_center: null,
      selected_location_muncipal: null,
      selected_location_medical_supplies: null,

      info_box_open_health_center: false,
      info_box_open_muncipal: false,
      info_box_open_medical_supplies: false,

      dashboard_data: {},
      dashboard_locations_data: {},
      dashboard_consumed_muncipals_data: {},
      dashboard_consumed_helth_center_data: {},
      dashboard_consumed_vaccine_data: {},

      most_consumed_muncipals_labels: [],

      most_consumed_muncipals_datasets: [
        
          {
            color: '#FFF',
              label: ' ',
              backgroundColor: '#f87979',
              data: []
          },
          
      ],


      filter_most_consumed_muncipals_date_from:'',
      filter_most_consumed_muncipals_date_to:'',


      most_consumeds_helth_center_labels: [],

      most_consumeds_helth_center_datasets: [
        
          {
            color: '#FFF',
              label: ' ',
              backgroundColor: '#3574ef',
              data: []
          },
          
      ],

      filter_most_consumed_helth_center_date_from:'',
      filter_most_consumed_helth_center_date_to:'',

      most_consumeds_Vaccine_labels: [],

      most_consumeds_Vaccine_datasets: [
        
          {
            color: '#FFF',
              label: ' ',
              backgroundColor: '#49cc90',
              data: []
          },
          
      ],

      filter_most_consumed_vaccine_date_from:'',
      filter_most_consumed_vaccine_date_to:'',


      
      data: [40, 20, 12, 39, 10, 40, 39, 80, 40, 20],
    };
  },

  created() {
    this.getDashboardLocations();



  },
  methods: {

    getDashboardLocations() {
      Dashboard.getDashboardLocationsForAdministrationOfVaccinations()
        .then((res) => {
          setTimeout(() => {
            
            this.dashboard_locations_data = res.data;

            this.markers_location_of_health_center = this.dashboard_locations_data.location_of_health_center;
            this.markers_location_of_muncipal = this.dashboard_locations_data.location_of_muncipal;
            this.markers_location_medical_supplies = this.dashboard_locations_data.location_medical_supplies;

           


            //   for (let index = 0; index < this.dashboard_data.most_consumed_muncipals.length; index++) {
            //     const element = this.dashboard_data.most_consumed_muncipals[index];

            //     this.most_consumed_muncipals_labels.push(element.name)  
            //     this.most_consumed_muncipals_datasets[0].data.push(element.total_obtained_Vaccines) 
            //   }

            //   for (let index = 0; index < this.dashboard_data.most_consumeds_helth_center.length; index++) {
            //     const element = this.dashboard_data.most_consumeds_helth_center[index];

            //     this.most_consumeds_helth_center_labels.push(element.name)  
            //     this.most_consumeds_helth_center_datasets[0].data.push(element.total_obtained_Vaccines) 
            //   }

            //   for (let index = 0; index < this.dashboard_data.most_consumeds_Vaccine.length; index++) {
            //     const element = this.dashboard_data.most_consumeds_Vaccine[index];

            //     this.most_consumeds_Vaccine_labels.push(element.name_en)  
            //     this.most_consumeds_Vaccine_datasets[0].data.push(element.total_obtained_Vaccines) 
            //   }


              

            // this.total = this.dashboard_data.total;
          }, 10);
        })
        .catch((err) => {
          

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },
    
    openInfoWindow(location, plase) {
      switch (plase) {
        case 1:
          this.selected_location_health_center = location;
          this.info_box_open_health_center = true;
          break;

        case 2:
          this.selected_location_muncipal = location;
          this.info_box_open_muncipal = true;
          break;

        case 3:
          this.selected_location_medical_supplies = location;
          this.info_box_open_medical_supplies = true;
          break;

        // default:
        //     break;
      }
    },

    closeInfoWindow(plase) {
      switch (plase) {
        case 1:
          this.info_box_open_health_center = false;
          break;

        case 2:
          this.info_box_open_muncipal = false;
          break;

        case 3:
          this.info_box_open_medical_supplies = false;
          break;

        // default:
        //     break;
      }
    },

    getDashboardStatisticsNumber() {
      let loader = this.$loading.show({
        loader: this.loader,
      });

      console.log("this.dashboard_data.most_consumed_muncipals.length")

      Dashboard.getDashboardStatisticsNumberForAdministrationOfVaccinations()
        .then((res) => {
          setTimeout(() => {
            loader.hide();
            
            this.dashboard_data = res.data;

            this.getDashboardLocations();
            this.getDashboardMostConsumedMuncipal();
            this.getDashboardMostConsumedHealthCenter();
            this.getDashboardMostConsumedVaccine();
            
          }, 10);
        })
        .catch((err) => {
          loader.hide();

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    

    getDashboardMostConsumedMuncipal() {
      Dashboard.getDashboardMostConsumedMuncipalForAdministrationOfVaccinations(1, this.filter_most_consumed_muncipals_date_from, this.filter_most_consumed_muncipals_date_to)
        .then((res) => {
          setTimeout(() => {
            
            this.dashboard_consumed_muncipals_data = res.data;

              for (let index = 0; index < this.dashboard_consumed_muncipals_data.most_consumed_muncipals.length; index++) {
                const element = this.dashboard_consumed_muncipals_data.most_consumed_muncipals[index];

                this.most_consumed_muncipals_labels.push(element.name)  
                this.most_consumed_muncipals_datasets[0].data.push(element.total_obtained_Vaccines) 
              }

            //   for (let index = 0; index < this.dashboard_data.most_consumeds_helth_center.length; index++) {
            //     const element = this.dashboard_data.most_consumeds_helth_center[index];

            //     this.most_consumeds_helth_center_labels.push(element.name)  
            //     this.most_consumeds_helth_center_datasets[0].data.push(element.total_obtained_Vaccines) 
            //   }

            //   for (let index = 0; index < this.dashboard_data.most_consumeds_Vaccine.length; index++) {
            //     const element = this.dashboard_data.most_consumeds_Vaccine[index];

            //     this.most_consumeds_Vaccine_labels.push(element.name_en)  
            //     this.most_consumeds_Vaccine_datasets[0].data.push(element.total_obtained_Vaccines) 
            //   }


              

            // this.total = this.dashboard_data.total;
          }, 10);
        })
        .catch((err) => {
          

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

    getDashboardMostConsumedHealthCenter() {
      Dashboard.getDashboardMostConsumedHealthCenterForAdministrationOfVaccinations(1, this.filter_most_consumed_helth_center_date_from, this.filter_most_consumed_helth_center_date_to)
        .then((res) => {
          setTimeout(() => {
            
            this.dashboard_consumed_helth_center_data = res.data;

              for (let index = 0; index < this.dashboard_consumed_helth_center_data.most_consumeds_helth_center.length; index++) {
                const element = this.dashboard_consumed_helth_center_data.most_consumeds_helth_center[index];

                this.most_consumeds_helth_center_labels.push(element.name)  
                this.most_consumeds_helth_center_datasets[0].data.push(element.total_obtained_Vaccines) 
              }


          }, 10);
        })
        .catch((err) => {

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },


    getDashboardMostConsumedVaccine() {
      Dashboard.getDashboardMostConsumedVaccineForAdministrationOfVaccinations(1, this.filter_most_consumed_vaccine_date_from, this.filter_most_consumed_vaccine_date_to)
        .then((res) => {
          setTimeout(() => {
            
            this.dashboard_consumed_vaccine_data = res.data;


              for (let index = 0; index < this.dashboard_consumed_vaccine_data.most_consumeds_Vaccine.length; index++) {
                const element = this.dashboard_consumed_vaccine_data.most_consumeds_Vaccine[index];

                this.most_consumeds_Vaccine_labels.push(element.name_en)  
                this.most_consumeds_Vaccine_datasets[0].data.push(element.total_obtained_Vaccines) 
              }

          }, 10);
        })
        .catch((err) => {

          this.$swal.fire({
            icon: "error",
            title: "...عذرا",
            text: err.response.data.message,
          });
        });
    },

  },
};
</script>

<style></style>
