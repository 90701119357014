<template>
  <div class="">
    <BarChart />


  <br><br>

  <hr>

    <br><br>

  <DoughnutChart />

    <br><br>

    <hr>

    <br><br>

    <BubbleChart />

    <br><br>

    <hr>

    <br><br>


    <LineChart />

<br><br>

    <hr>

    <br><br>
     <PolarAreaChart />
<br><br>

    <hr>

    <br><br>


     <ScatterChart />

  </div>
</template>

<script>
import BarChart from '../../components/ChartJS/Bar.vue'
import DoughnutChart from '../../components/ChartJS/Doughnut.vue'
import BubbleChart from '../../components/ChartJS/Bubble.vue'
import LineChart from '../../components/ChartJS/Line.vue'
import PolarAreaChart from '../../components/ChartJS/PolarArea.vue'
import ScatterChart from '../../components/ChartJS/Scatter.vue'

export default {
  name: 'App',
  components: {
    BarChart,
    DoughnutChart,
    BubbleChart,
    LineChart,
    PolarAreaChart,
    ScatterChart,
  }
}
</script>
