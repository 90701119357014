<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">
                صرف لقاح للمسجل 
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="underline font-extrabold">{{ giving.citizen_name }}</span>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <span class="underline font-extrabold">{{ date_of_birth }}</span>



            </h1>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
         
            <errormessage :error="errors"></errormessage>
            <form class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4 sm:p-6 shadow border dark:border-slate-800 sm:rounded-md sm:overflow-hidden">
                <div class="grid md:grid-cols-2 gap-8">
                    
                    <div class="input" >
                        <label for="vaccine_id" class="block text-sm font-medium ">
                            اختر القاح 
                        </label>
                        <select
                            id="vaccine_id"
                            name="vaccine_id"
                            v-model="vaccine_id_selected"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>القاح</option>
                            <option
                                v-for="vaccine in vaccines_data"
                                :key="vaccine.id"
                                v-bind:value="vaccine.vaccineid"
                                class="text-lg"
                            >
                                {{ vaccine.vaccine_name_ar}}
                                &nbsp;
                                &nbsp;
                                {{ vaccine.vaccine_name }}
                            </option>

                        </select>
                    </div>

                    <div v-if="age_groups_length > 0" class="input" >
                        <label for="fixed_vaccines_id" class="block text-sm font-medium ">
                           الفئة العمرية 
                        </label>
                        <select
                            id="fixed_vaccines_id"
                            name="fixed_vaccines_id"
                            v-model="giving.fixed_vaccines_id"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>القاح</option>
                            <option
                                v-for="age in age_groups_data"
                                :key="age.id"
                                v-bind:value="age.id"
                                class="text-lg"
                            >
                                {{ age.description }} 
                            </option>
                        </select>
                    </div>
       
                </div>

                <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
                    <div class="inline w-full md:w-auto" :class=" $v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer' ">
                        <button
                            @click.prevent="addGivingVaccine"
                            v-if="vaccine_id_to_edit == ''"
                            :disabled="$v.$invalid"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                            إضافة
                        </button>

                        <button
                            @click.prevent="updateGivingVaccine"
                            v-if="vaccine_id_to_edit != ''"
                            :disabled="$v.$invalid"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                            تعديل
                        </button>
                    </div>
                </div>
            </form>

            <div class="py-2 align-middle inline-block min-w-full mt-6">
                <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                    <table class="min-w-full divide-y divide-gray-200">
                        <div class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right">
                            <div scope="col" class="w-1/12 px-4 py-3 hidden lg:block">
                                الرقم
                            </div>
                            <div scope="col" class="w-7/12 md:w-4/12 px-2 py-3">
                                اللقاح
                            </div>
                            <div scope="col" class="w-/12 md:w-1/12 px-2 py-3 hidden md:block">
                                الفئة
                            </div>

                            <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
                                مركز التطعييم
                            </div>

                            <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
                                التاريخ
                            </div>

                            <div scope="col" class="w-3/12 md:w-2/12 px-2 py-3 hidden md:block">
                                الساعة
                            </div>

                           
                        </div>
                        <div v-for="(obtained, personIdx) in obtained_vaccines_data" :key="obtained.id"
                            class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400" 
                            :class=" personIdx % 2 === 0 ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-800' "
                        >
                            <div class="w-1/12 px-4 py-4 whitespace-nowrap text-sm font-medium hidden md:block">
                                {{ personIdx+1 }}
                            </div>

                            <div class="w-7/12 md:w-4/12 px-2 py-4 whitespace-nowrap text-sm truncate">
                                {{ obtained.vaccine_name_en }} 
                            </div>

                            <div class="w-7/12 md:w-1/12 px-2 py-4 whitespace-nowrap text-sm truncate">
                                {{ obtained.description }}
                            </div>
                            <div class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                                {{ obtained.health_center_name }}
                            </div>

                            <div class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                                {{ obtained.insert_at }}
                            </div>

                            <div class="md:w-2/12 px-2 py-4 whitespace-nowrap text-sm truncate hidden md:flex justify-between">
                                {{ obtained.time }}

                                <button 
                                    title="تعديل" 
                                    @click="edit(obtained.id, obtained.vaccineid)"
                                    class="mx-2"
                                >
                                    <svg
                                        class="h-6 w-6 stroke-current hover:text-blue-600"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </button>
                            </div>

                            
                        </div>
                    </table>
                </div>
            </div>

        </div>
    </div>
</template>

<script>

import errormessage from "@/components/ErrorMessage"
import { required } from "vuelidate/lib/validators";
import GivingVaccineService from "@/services/GivingVaccineService";

import VaccinationsStorageService from "@/services/VaccinationsStorageService";

export default {
    components: {
        errormessage
    },
    data() {
        return {
            errors: null,
            
            vaccines_data:{},
            obtained_vaccines_data: {},
            date_of_birth: this.$route.params.date_of_birth,
            giving: {
                citizen_name : this.$route.params.citizen_name,
                citizenid : this.$route.params.id,
                health_centerid : this.$route.params.vaccination_center_id,
                vaccineid : '',
                fixed_vaccines_id: '',
                
            },

            vaccine_id_to_edit: '',

            vaccine_id_selected : '',

            age_groups_data: {},

            age_groups_length: 0
        };
    },
    
    created() {

        this.getVaccines();
        this.getObtainedVaccinese();
    },

    validations: {
        giving: {
            vaccineid: {
                required,
            },
            fixed_vaccines_id: {
                required,
            },
        },
    },


    watch: {
        vaccine_id_selected: function() {
            this.age_groups_data = {}
            this.age_groups_length = 0

            this.giving.fixed_vaccines_id = ''
            this.giving.vaccineid = this.vaccine_id_selected

            this.getTheAgeGroupsForTheVaccine(this.vaccine_id_selected);
        },
    },

    methods: {
        getTheAgeGroupsForTheVaccine() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            GivingVaccineService.getTheAgeGroupsForTheVaccine(this.vaccine_id_selected)
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.age_groups_data = res.data;

                        this.age_groups_length = this.age_groups_data.length;
                        
                        // if(this.age_groups_data.length == 1){
                        //     this.giving.fixed_vaccines_id = this.age_groups_data[0].id
                        //     this.age_groups_length = 0
                        // }
                        // console.log(this.age_groups_data[0])
                    }, 10);
                })
                .catch(() => {
                    loader.hide();
                });
        },

        edit(id, vaccineid){
            this.vaccine_id_to_edit = id;
            this.giving.vaccineid = vaccineid;
        },

        getObtainedVaccinese() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            GivingVaccineService.getObtainedVaccineseWithoutPagination(this.$route.params.id)
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.obtained_vaccines_data = res.data.data;

                        console.log(this.obtained_vaccines_data)
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        getVaccines() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            VaccinationsStorageService.getVaccinationsStorageWithoutRepeat(this.giving.health_centerid)
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.vaccines_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        updateGivingVaccine() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                id: this.vaccine_id_to_edit,
                citizenid: this.giving.citizenid,
                health_centerid: this.giving.health_centerid,
                vaccineid: this.giving.vaccineid,
                fixed_vaccines_id: this.giving.fixed_vaccines_id,
            }

            GivingVaccineService.updateGivingVaccine(data)
                .then((resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.getObtainedVaccinese();
                        // this.$router.push({ name: "citizens_vaccination_center", params: { vaccination_center_id: this.giving.health_centerid } });
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.errors.name,
                    })
                });
        },

        addGivingVaccine() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {
                citizenid: this.giving.citizenid,
                health_centerid: this.giving.health_centerid,
                vaccineid: this.giving.vaccineid,
                fixed_vaccines_id: this.giving.fixed_vaccines_id,
            }

            GivingVaccineService.givingVaccine(data)
                .then((resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.getObtainedVaccinese();
                        // this.$router.push({ name: "citizens_vaccination_center", params: { vaccination_center_id: this.giving.health_centerid } });
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.errors.name,
                    })
                });
        },
    },
};
</script>

<style scoped>
.input.invalid label {
    color: red;
}
.input.invalid input {
    border: 1px solid red;
    background-color: #ffc9aa;
}

[type="submit"],
button {
    cursor: inherit;
    color: inherit;
}
</style>
