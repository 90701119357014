<template>
    <div class="antialiased text-slate-500 dark:text-slate-400 bg-gray-50 dark:bg-slate-900 relative">
        <VerticalNavMenu></VerticalNavMenu>

        <div class="xl:pr-64 min-h-screen flex flex-col flex-1 pb-8">
            <HorizontalNavMenu></HorizontalNavMenu>
            <div class="max-w-7xl mx-auto w-full px-4 sm:px-6 lg:px-8">

                

                <main class="flex-1 py-6">
                    <router-view />
                </main>

            </div>
        </div>

        <div class="text-white z-20 w-full bg-slate-900 dark:bg-slate-800 xl:pr-72 fixed bottom-0 py-2">
            الرجاء التواصل مع فريق شركة كيان الحضارية في حالة وجود اي استفسارات - 0919594000
        </div>
    </div>
</template>

<script>
import VerticalNavMenu from "../components/VerticalNavMenu.vue";
import HorizontalNavMenu from "../components/HorizontalNavMenu.vue";
export default {
    components: {
        VerticalNavMenu,
        HorizontalNavMenu
    },

    data() {
        return {};
    },
    
    mounted() {},

    computed: {
        // isLoggedIn() {
        //     return this.$store.getters["auth/loggedIn"];
        // },
    },

    methods: {
        async logout(e) {
            e.preventDefault();
            // const authUser = await this.$store.dispatch("auth/logout");
        },
    },
};
</script>

<style></style>
