<template>
  <div class="">
    <div
      class="bg-[url('assets/images/Group.png')] bg-gray-50 h-56 flex items-center border-t border-gray-200"
    >
      <div class="w-full px-4 lg:px-12 max-w-container mx-auto">
        <h2 class="text-4xl">معرض الصور </h2>
        <p class="flex gap-2 mt-8">
          <span>الرئيسية</span>
          <span>-</span>
          <span class="font-medium">معرض الصور </span>
        </p>
      </div>
    </div>

   
  </div>
</template>

<script>
export default {
  mounted() {
    window.scroll(0, 0);
  }
};
</script>

<style></style>
