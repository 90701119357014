<template>
  <div class="">
    <div class="bg-gray-50 h-56 flex items-center border-t border-gray-200">
      <div
        class="w-full px-4 lg:px-12 max-w-container mx-auto flex flex-col items-center"
      >
        <h2 class="text-4xl">404</h2>
        <p class="flex gap-2 mt-8">
          <span>الرئيسية</span>
          <span>-</span>
          <span class="font-medium"> --------- </span>
        </p>
      </div>
    </div>

    <div
      class="px-4 lg:px-12 max-w-container mx-auto flex gap-y-8 flex-col lg:flex-row justify-around items-center mt-10 md:mt-24"
    >
      <svg
        class="w-60 h-60 md:w-96 md:h-96 xl:w-[582px]"
        width="588"
        height="557"
        viewBox="0 0 588 557"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_1_9035)">
          <path
            opacity="0.1"
            d="M198.767 539.7C351.311 595.221 517.579 523.171 570.136 378.77C622.694 234.37 541.639 72.3013 389.094 16.7797C236.55 -38.7419 70.2824 33.3087 17.725 177.709C-34.8325 322.11 46.2228 484.178 198.767 539.7Z"
            fill="url(#paint0_linear_1_9035)"
          />
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M405.057 71.4492L473.937 156.955V319.208L502.081 382.422C502.589 383.562 502.867 384.791 502.9 386.038C502.933 387.285 502.719 388.527 502.272 389.692C501.825 390.856 501.153 391.922 500.294 392.827C499.435 393.732 498.406 394.459 497.266 394.966L473.938 405.353V430.572C473.938 433.092 472.937 435.508 471.155 437.29C469.373 439.071 466.957 440.072 464.438 440.072H395.957L237.323 510.699C236.184 511.207 234.955 511.485 233.708 511.518C232.461 511.55 231.219 511.337 230.054 510.89C228.889 510.443 227.824 509.771 226.919 508.912C226.014 508.053 225.287 507.024 224.78 505.884L195.479 440.072H179.894C177.374 440.072 174.958 439.071 173.176 437.29C171.394 435.508 170.394 433.092 170.394 430.572V383.728L82.5755 186.49C82.0679 185.35 81.7898 184.122 81.7572 182.874C81.7245 181.627 81.9379 180.385 82.3851 179.221C82.8323 178.056 83.5046 176.99 84.3636 176.085C85.2226 175.18 86.2515 174.454 87.3915 173.946L170.392 136.991V80.9502C170.392 78.4307 171.392 76.0143 173.174 74.2327C174.956 72.4511 177.372 71.4502 179.892 71.4502L405.057 71.4492Z"
            fill="white"
          />
          <path
            d="M404.586 70.9736L473.941 156.48H404.586V70.9736Z"
            fill="#102A43"
            fill-opacity="0.27"
          />
          <path
            d="M170.352 377.962V129.997L86.4455 168.217C84.6276 169.045 83.2088 170.556 82.4962 172.422C81.7835 174.288 81.8343 176.36 82.6375 178.189L170.352 377.962Z"
            fill="#F2C60D"
          />
          <path
            d="M225.705 506.885L195.105 440.072H395.562L235.685 510.672C233.858 511.479 231.786 511.535 229.919 510.826C228.051 510.117 226.537 508.701 225.705 506.885Z"
            fill="#F2C60D"
          />
          <path
            d="M497.906 394.547L473.941 404.92V317.99L502.35 383.29C502.799 384.321 503.039 385.43 503.059 386.554C503.078 387.678 502.876 388.795 502.463 389.84C502.05 390.886 501.435 391.84 500.653 392.647C499.871 393.455 498.938 394.101 497.906 394.547Z"
            fill="#F2C60D"
          />
          <path
            d="M271.475 263.474V234.319L250.855 263.474H271.475ZM271.609 286.249V270.526H243.398V262.62L272.867 221.741H279.695V263.474H289.174V270.526H279.695V286.249H271.609ZM317.16 221.921C325.486 221.921 331.505 225.35 335.219 232.208C338.094 237.509 339.531 244.771 339.531 253.995C339.531 262.74 338.229 269.972 335.623 275.692C331.85 283.898 325.68 288.001 317.115 288.001C309.389 288.001 303.639 284.647 299.865 277.938C296.721 272.338 295.148 264.821 295.148 255.388C295.148 248.08 296.092 241.806 297.979 236.565C301.512 226.802 307.906 221.921 317.16 221.921ZM317.07 280.634C321.263 280.634 324.602 278.777 327.088 275.063C329.574 271.35 330.816 264.432 330.816 254.31C330.816 247.002 329.918 240.998 328.121 236.296C326.324 231.564 322.835 229.198 317.654 229.198C312.893 229.198 309.404 231.444 307.188 235.937C305.001 240.399 303.908 246.987 303.908 255.702C303.908 262.261 304.612 267.532 306.02 271.515C308.176 277.594 311.859 280.634 317.07 280.634ZM373.896 263.474V234.319L353.277 263.474H373.896ZM374.031 286.249V270.526H345.82V262.62L375.289 221.741H382.117V263.474H391.596V270.526H382.117V286.249H374.031Z"
            fill="#102A43"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1_9035"
            x1="554.692"
            y1="177.133"
            x2="71.548"
            y2="546.109"
            gradientUnits="userSpaceOnUse"
          >
            <stop stop-color="#486581" />
            <stop offset="1" stop-color="#486581" stop-opacity="0" />
          </linearGradient>
          <clipPath id="clip0_1_9035">
            <rect width="587.86" height="556.48" fill="white" />
          </clipPath>
        </defs>
      </svg>

      <div class="text-center lg:text-right">
        <p class="text-4xl">الصفحة المطلوبة غير موجودة</p>
        <p class="font-medium mt-8 max-w-lg">
          عفواً، الصفحة التي تحاول الوصول إليها غير موجودة، الرجاء التأكد من
          إدخالك للرابط بشكل صحيح.
        </p>
        <button
          class="bg-secondary-100 text-white w-fit rounded py-2 md:py-3 px-3 md:px-5 mt-8"
        >
          ارسال الرسالة
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style></style>
