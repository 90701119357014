<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">إضافة مركز تطعيم</h1>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
         
            <errormessage :error="errors"></errormessage>
            <form
                @submit.prevent="addVaccinationCenter"
                class="bg-white dark:bg-slate-800 text-slate-500 dark:text-slate-400 py-6 px-4  sm:p-6 shadow border dark:border-slate-800  sm:rounded-md sm:overflow-hidden"
            >
                <div class="grid md:grid-cols-4 gap-8">

                    <div class="input" :class="{ invalid: $v.vaccination_center.name.$error }">
                        <label for="vaccination_center_name" class="block text-sm font-medium ">
                            الاسم
                        </label>
                        <input
                            type="text"
                            id="vaccination_center_name"
                            placeholder="أكتب الاسم."
                            v-model="vaccination_center.name"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.vaccination_center.name.$touch()"
                        />
                        <p v-if="!$v.vaccination_center.name.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الاسم على
                            {{ $v.vaccination_center.name.$params.minLength.min }} أحرف على
                            الأقل.
                        </p>
                    </div>

                    <div class="input" :class="{ invalid: $v.vaccination_center.address.$error }">
                        <label for="vaccination_center_address" class="block text-sm font-medium ">
                            العنوان
                        </label>
                        <input
                            type="text"
                            id="vaccination_center_address"
                            placeholder="أكتب العنوان."
                            v-model="vaccination_center.address"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                            @blur="$v.vaccination_center.address.$touch()"
                        />
                        <p v-if="!$v.vaccination_center.address.minLength" class="text-xs text-red-500 mt-2">
                            يجب أن يحتوي الاسم على
                            {{ $v.vaccination_center.address.$params.minLength.min }} أحرف على
                            الأقل.
                        </p>
                    </div>

                    <div class="input" >
                        <label for="vaccination_center_longitude" class="block text-sm font-medium ">
                            خط الطول 
                        </label>
                        <input
                            type="text"
                            id="vaccination_center_longitude"
                            placeholder="أكتب خط الطول ."
                            v-model="vaccination_center.longitude"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        
                    </div>

                    <div class="input" >
                        <label for="vaccination_center_latitude" class="block text-sm font-medium ">
                            خط العرض
                        </label>
                        <input
                            type="text"
                            id="vaccination_center_latitude"
                            placeholder="أكتب خط العرض."
                            v-model="vaccination_center.latitude"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        />
                        
                    </div>

                  

                    <div class="input" >
                        <label for="administrator_id" class="block text-sm font-medium ">
                            اختر المسؤول 
                        </label>
                        <select
                            id="administrator_id"
                            name="administrator_id"
                            v-model="prepar_administrator_id"
                            class="mt-1 block w-full border border-solid border-gray-300 rounded-md shadow-sm py-2 px-3 dark:text-gray-200 bg-white dark:bg-gray-600 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                        >
                            <option  disabled selected>المسؤول</option>
                            <option
                                v-for="administrator in administrators_data"
                                :key="administrator.id"
                                v-bind:value="administrator.id"
                            >
                                {{ administrator.full_name }}
                            </option>

                        </select>
                    </div>
       
                </div>

                <div class="text-white w-full md:w-auto flex md:justify-end mt-8">
                    <div class="inline w-full md:w-auto" :class=" $v.$invalid ? 'cursor-not-allowed' : 'cursor-pointer' ">
                        <button
                            :disabled="$v.$invalid"
                            type="submit"
                            class="border border-transparent rounded-md shadow-sm w-full md:w-auto py-2 px-16 inline-flex justify-center text-sm font-medium focus:outline-none"
                            :class=" $v.$invalid ? 'bg-gray-600' : 'bg-blue-600 hover:bg-blue-700 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500'">
                            إضافة
                        </button>
                    </div>
                </div>
            </form>
        </div>
    </div>
</template>

<script>

import errormessage from "@/components/ErrorMessage.vue"
import { required, minLength } from "vuelidate/lib/validators";
import VaccinationCentersService from "@/services/VaccinationCentersService";

import MunicipalsService from "@/services/MunicipalsService";
import AdministratorsService from "@/services/AdministratorsService";

export default {
    components: {
        errormessage
    },
    data() {
        return {
            errors: null,
            municipals_data: {},
            administrators_data:{},
            vaccination_center: {
                name : '',
                address : '',
                longitude : '',
                latitude : '',
               
                municipalid: this.$route.params.municipal_id,
                administrator_id : '',
                role_id : '',
            },
            prepar_administrator_id : '',
            // loader: "dots",
        };
    },
    watch: {
        prepar_administrator_id: function() {
            
            let administrator = this.administrators_data.find(
                (element) => element.id == this.prepar_administrator_id
            );

            this.vaccination_center.administrator_id = administrator.id
            this.vaccination_center.role_id = administrator.role_id

        },
    },

    created() {
        this.getAdministrators();
        this.MedicalSupplies();
        
    },
    validations: {
        vaccination_center: {
            name: {
                required,
                minLength: minLength(3),
            },
            address: {
                required,
                minLength: minLength(3),
            },
            
            municipalid: {
                required,
            },
            administrator_id: {
                required,
            },
        },
    },
    methods: {

         getAdministrators() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            AdministratorsService.getAdministratorsByRole(5)
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.administrators_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },
        
        MedicalSupplies() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            MunicipalsService.getMunicipalsWithoutPagination()
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();

                        this.municipals_data = res.data;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        addVaccinationCenter() {
            let loader = this.$loading.show({
                loader: this.loader,
            });

            let data = {

                health_Center:{
                    name : this.vaccination_center.name,
                    address : this.vaccination_center.address,

                    longitude : Number(this.vaccination_center.longitude),
                    latitude : Number(this.vaccination_center.latitude),

                    municipalid : this.vaccination_center.municipalid,
                },
                administrator:{
                    administrator_id: this.vaccination_center.administrator_id,
                    role_id: this.vaccination_center.role_id,
                    type: 5
                }
            }

            VaccinationCentersService.createVaccinationCenter(data)
                .then((resp) => {
                    setTimeout(() => {
                        loader.hide();
                        this.$swal.fire({
                            icon: 'success',
                            text: resp.data.message,
                        })
                        this.$router.push({ name: "vaccinationCentersByMunicipal" });
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();

                    this.errors = err.response.data.errors
                    this.$swal.fire({
                        icon: 'error',
                        title: '...عذرا',
                        text: err.response.data.errors.name,
                    })
                });
        },
    },
};
</script>

<style scoped>
.input.invalid label {
    color: red;
}
.input.invalid input {
    border: 1px solid red;
    background-color: #ffc9aa;
}

[type="submit"],
button {
    cursor: inherit;
    color: inherit;
}
</style>
