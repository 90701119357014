<template>
    <div
        class="font-sans min-h-screen bg-gray-100 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8"
    >
        <div class="max-w-md w-full space-y-8">
            <div>
                <img
                    class="mx-auto h-44 w-auto"
                    src="../../../assets/img/ncdc_logo_1.png"
                    alt="Workflow"
                />

                <h2
                    class="mt-16 text-center text-3xl font-extrabold text-gray-900"
                >
                    منصة تحصين
                </h2>


                <h2
                    class="mt-6 text-center text-3xl font-extrabold text-gray-900"
                >
                    تسجيل الدخول إلى حسابك
                </h2>
            </div>
            <div class="mt-8 space-y-6" method="POST">
                <input type="hidden" name="remember" value="true" />
                <div class="rounded-md shadow-sm -space-y-px">
                    <div>
                        <label for="email-address" class="sr-only">الاسم</label>
                        <input
                            id="email-address"
                            type="text"
                            autocomplete="email"
                            v-model="name"
                            required
                            autofocus
                            class="appearance-none rounded-none relative block w-full px-3 py-2 bg-white border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                            placeholder="اسم المستخدم"
                        />
                    </div>
                    <div>
                        <label for="password" class="sr-only"
                            >كلمة المرور</label
                        >
                        <input
                            id="password"
                            type="password"
                            autocomplete="current-password"
                            v-model="password"
                            required
                            class="appearance-none rounded-none relative block w-full px-3 py-2 bg-white border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-blue-500 focus:border-blue-500 focus:z-10 sm:text-sm"
                            placeholder="كلمة المرور"
                        />
                    </div>
                </div>

                <div class="flex items-center justify-between">
                    <div class="text-sm">
                        <a
                            href="#"
                            class="font-medium text-blue-600 hover:text-blue-500"
                        >
                            نسيت كلمة المرور؟
                        </a>
                    </div>

                    <div class="flex items-center justify-end">
                        <input
                            id="remember-me"
                            name="remember-me"
                            type="checkbox"
                            class="h-4 w-4 text-blue-600 focus:ring-blue-500 border-gray-300 rounded"
                        />
                        <label
                            for="remember-me"
                            class="mr-2 block text-sm text-gray-900"
                        >
                            تذكرنى
                        </label>
                    </div>
                </div>

                <div>
                    <button
                        @click="handleSubmit"
                        type="submit"
                        style="color: white"
                        class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-red-600 bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                    >
                        <span
                            class="absolute left-0 inset-y-0 flex items-center pl-3"
                        >
                            <svg
                                class="h-5 w-5 text-blue-500 group-hover:text-blue-400"
                                x-description="Heroicon name: solid/lock-closed"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                aria-hidden="true"
                            >
                                <path
                                    fill-rule="evenodd"
                                    d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z"
                                    clip-rule="evenodd"
                                ></path>
                            </svg>
                        </span>
                        تسجيل الدخول
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// import AuthService from "../../services/AuthService";
export default {
    
    components: {
    },
    data() {
        return {
            name: "",
            password: "",
        };
    },
    created() {},
    // computed: {
    //     ...mapState({
    //         error: (state) => state.auth.error,
    //     }),
    // },
    methods: {

        async handleSubmit(e) {
            let loader = this.$loading.show({
                loader: this.loader,
            });
            e.preventDefault();

            const payload = {
                userName: this.name,
                password: this.password,
            };

            try {
              
                const authUser = await this.$store.dispatch( "auth/doLogin", payload );
                setTimeout(() => {
                    if (authUser) {
                        loader.hide();


                        console.log(authUser.data.role.id)
                        if (authUser.data.role.id == 'f1291955-df5e-4fcc-bc63-1b16aa99b63b' || authUser.data.role.id == '91a5f652-8428-43d4-8648-3cb3c906d471') {
                            this.$router.push({ name: 'dashboardAdministrationOfVaccinations'});
                        }
                        else if (authUser.data.role.id == '03a85b59-5047-48bd-a681-87d38bc207e5') {
                            this.$router.push({ name: 'dashboardVaccinationCenters'});
                        }
                        else if (authUser.data.role.id == '13d34058-124e-4d83-8a5d-9127e9e6c2d7') {
                            this.$router.push({ name: 'dashboardMedicalSupplies'});
                        }
                        else if (authUser.data.role.id == '1c74f9e8-faba-4051-bebc-db08ed11dbcc') {
                            this.$router.push({ name: 'dashboardMunicipalities'});
                        }
                        


                        
                        
                    }
                    else{
                        loader.hide();
                        this.$swal.fire({
                            icon: 'error',
                            title: '...عذرا',
                            text: this.$store.state.auth.loginError,
                        })
                    }
                }, 10);
            } catch (error) {
                loader.hide();
                this.$swal.fire({
                    icon: 'error',
                    title: '...عذرا',
                    text: 'عذراً، لقد حدث خطأ ما نعمل على إصلاح هذه المشكلة.',
                })
               
            }
        },

    },
};
</script>
