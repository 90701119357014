<template>
    <div class="mt-16 xl:mt-0">
        <div class="flex justify-between items-center">
            <h1 class="text-xl md:text-2xl font-semibold text-slate-900 dark:text-white">المسؤولين</h1>

            <router-link
                title="إضافة"
                :to="{ name: 'addadministrator' }"
                class="w-28 md:w-40 text-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            >
                إضافة +
            </router-link>
        </div>

        <div class="py-2 align-middle inline-block min-w-full mt-6">
            <div class="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table class="min-w-full divide-y divide-gray-200">
                    <div class="w-full flex bg-gray-50 dark:bg-slate-800 text-slate-500 dark:text-slate-400 text-xs font-medium tracking-wider text-right">
                        <div
                            scope="col"
                            class="w-1/12 px-6 py-3 hidden lg:block"
                        >
                            الرقم
                        </div>
                        <div
                            scope="col"
                            class="w-7/12 md:w-4/12 lg:w-3/12 px-6 py-3"
                        >
                            الاسم
                        </div>
                        <div
                            scope="col"
                            class="w-3/12 lg:w-2/12 px-6 py-3 hidden md:block"
                        >
                            اسم المستخدم
                        </div>

                        <div
                            scope="col"
                            class="w-3/12 lg:w-4/12 px-6 py-3 hidden md:block"
                        >
                            الصفة
                        </div>

                        <div
                            scope="col"
                            class="w-5/12 md:w-2/12 px-6 py-3"
                        >
                            الإجراءات
                        </div>
                    </div>
                    <div v-for="(administrator, personIdx) in administratorsData" :key="administrator.id"
                        class="hover:bg-gray-100 flex hover:dark:bg-slate-700 text-slate-500 dark:text-slate-400" 
                        :class=" personIdx % 2 === 0 ? 'bg-white dark:bg-slate-800' : 'bg-gray-50 dark:bg-slate-800' "
                    >
                        <div class="w-1/12 px-6 py-4 whitespace-nowrap text-sm font-medium hidden lg:block">
                            {{ personIdx+1 }}
                        </div>
                        <div class="w-7/12 md:w-4/12 lg:w-3/12 px-6 py-4 whitespace-nowrap text-sm truncate">
                            {{ administrator.full_name }} 
                        </div>
                        <div class="w-3/12 lg:w-2/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ administrator.usename }}
                        </div>

                        <div class="w-3/12 lg:w-4/12 px-6 py-4 whitespace-nowrap text-sm truncate hidden md:block">
                            {{ administrator.role_name }}
                        </div>

                        <div class="w-5/12 md:w-2/12 px-6 py-4">
                            <div class="w-full flex items-center justify-between">

                                <router-link title="تعديل" :to="{ name: 'editadministrator', params: { id: administrator.id },}" class="mx-2">
                                    <svg
                                        class="h-6 w-6 stroke-current hover:text-blue-600"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 24 24"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M11 4H4C3.46957 4 2.96086 4.21071 2.58579 4.58579C2.21071 4.96086 2 5.46957 2 6V20C2 20.5304 2.21071 21.0391 2.58579 21.4142C2.96086 21.7893 3.46957 22 4 22H18C18.5304 22 19.0391 21.7893 19.4142 21.4142C19.7893 21.0391 20 20.5304 20 20V13"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M18.5 2.49998C18.8978 2.10216 19.4374 1.87866 20 1.87866C20.5626 1.87866 21.1022 2.10216 21.5 2.49998C21.8978 2.89781 22.1213 3.43737 22.1213 3.99998C22.1213 4.56259 21.8978 5.10216 21.5 5.49998L12 15L8 16L9 12L18.5 2.49998Z"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </router-link>

                                <button title="حذف" class="" @click="deleteAdministrator(administrator.id)">
                                    <svg
                                        class="h-6 w-6 stroke-current hover:text-red-600"
                                        width="25"
                                        height="25"
                                        viewBox="0 0 25 25"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            d="M3.80444 6.50317H5.80444H21.8044"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                        <path
                                            d="M8.80444 6.50317V4.50317C8.80444 3.97274 9.01516 3.46403 9.39023 3.08896C9.7653 2.71389 10.274 2.50317 10.8044 2.50317H14.8044C15.3349 2.50317 15.8436 2.71389 16.2187 3.08896C16.5937 3.46403 16.8044 3.97274 16.8044 4.50317V6.50317M19.8044 6.50317V20.5032C19.8044 21.0336 19.5937 21.5423 19.2187 21.9174C18.8436 22.2925 18.3349 22.5032 17.8044 22.5032H7.80444C7.27401 22.5032 6.7653 22.2925 6.39023 21.9174C6.01516 21.5423 5.80444 21.0336 5.80444 20.5032V6.50317H19.8044Z"
                                            stroke-width="2"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        />
                                    </svg>
                                </button>

                            </div>
                        </div>
                    </div>
                </table>
            </div>
        </div>

       <div class="flex justify-end mt-8 mx-auto px-4 sm:px-6 lg:px-8 w-full bg-white relative">
            <pagination
                dir="rtl"
                v-model="page_number"
                :per-page="page_size"
                :records="total"
                @paginate="getAdministrators"
                class="z-10"
            />
        </div>
    </div>
</template>

<script>
// import axios from "axios";

import AdministratorsService from "@/services/AdministratorsService";

export default {
    // components: { pagination: LaravelVuePagination },
    computed: {},
    data() {
        return {
            administratorsData: {},
            page_number: 1,
            page_size: 10,
            total: 0,
        };
    },
    created() {
        this.getAdministrators();
    },
    methods: {
        // getPrivilegesKey(key) {
        //     return Object.keys(key);
        // },
         getAdministrators() {
            let loader = this.$loading.show({
                loader: this.loader,
            });
            AdministratorsService.getAdministrators(
                this.page_number,
                this.page_size
            )
                .then((res) => {
                    setTimeout(() => {
                        loader.hide();
                        this.administratorsData = res.data.list;


                        console.log(this.administratorsData)
                        this.total = res.data.total;
                    }, 10);
                })
                .catch((err) => {
                    loader.hide();
                    this.$swal.fire({
                        icon: "error",
                        title: "...عذرا",
                        text: err.response.data.message,
                    });
                });
        },

        deleteAdministrator(id) {
            this.$swal
                .fire({
                    title: "هل أنت متأكد؟",
                    text: "!لن تتمكن من التراجع عن هذا",
                    icon: "warning",
                    showCancelButton: true,
                    cancelButtonColor: "#d33",
                    cancelButtonText: "إلغاء",
                    confirmButtonColor: "#3085d6",
                    confirmButtonText: "!نعم ، احذفها",
                })
                .then((result) => {
                    if (result.isConfirmed) {
                        let loader = this.$loading.show({
                            loader: this.loader,
                        });

                        AdministratorsService.deleteAdministrator(id)
                            .then((resp) => {
                                let i = this.administratorsData
                                    .map((item) => item.id)
                                    .indexOf(id); // find index of your object
                                this.administratorsData.splice(i, 1);
                                loader.hide();
                                this.$swal.fire({
                                    text: resp.data.message,
                                    icon: "success",
                                    confirmButtonText: "حسنا",
                                });
                            })
                            .catch((err) => {
                                loader.hide();

                                this.$swal.fire({
                                    icon: "error",
                                    title: "...عذرا",
                                    text: err.response.data.errors.name,
                                });
                            });
                    }
                });
        },
    },
};
</script>


<style>
.VuePagination__count {
  display: none;
}

.VuePagination {
  width: 100%;
}

.VuePagination nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.pagination {
  display: flex;
}

.page-link {
  background-color: red;
}

.page-item {
  /* margin-left: .5rem;
        margin-right: .5rem;*/
}

.page-link {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;

  font-size: 0.75rem;
  /* line-height: 1.25rem; */

  font-weight: 500;
  border-width: 1px;

  --tw-border-opacity: 0;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity));

  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));

  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.page-link:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.active {
  background-color: rgba(16, 185, 129);
  color: #fff;
}

.VuePagination nav ul {
  padding-top: 0.3rem;
  padding-bottom: 0.5rem;
  border-radius: 0.375rem;
  overflow: hidden;
}
</style>
