<template>
      <div class="">
            <headeraca></headeraca>
            
            <router-view></router-view>

            <footeraca></footeraca>
      </div>
</template>

<script>

import headeraca from "../components/header.vue";
import footeraca from "../components/footer.vue";


export default {
      components: {
            headeraca,
            footeraca
    },
}
</script>

<style>
</style>
