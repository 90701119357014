import * as API from "./API";

import store from "../store";

export default {
    // getMuncipalOrderWithoutPagination(is_approved, id) {
    //     return API.apiClient.get(`/api/Order/get_orders_for_health_center?is_approved=${is_approved}&id=${id}`);
    // },

    getOrder(page_number, page_size) {
        return API.apiClient.get(`/api/Order/all?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },


    getMonthlyVaccinationMovement(id, vaccine_id) {
        return API.apiClient.get(`/api/Order/monthly_vaccination_movement?id=${id}&vaccine_id=${vaccine_id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },





    // getMuncipalOrderById(id) {
    //     return API.apiClient.get(`/api/Order/Order?id=${id}`);
    // },

    createOrder(order) {
        return API.apiClient.post(`/api/Order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    addToCary(order) {
        return API.apiClient.post(`/api/Order/cart`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    updateToCary(order) {
        return API.apiClient.put(`/api/Order/update_order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    orderApproval(id) {
        return API.apiClient.get(`/api/Order/change_state_of_order_from_center?id=${id}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },

    OrderReject(order) {
        return API.apiClient.put(`/api/Order/reject_order`, order, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    getOrderWithMedicalSupply(page_number, page_size) {
        return API.apiClient.get(`/api/Order/all_orders?page_number=${page_number}&page_size=${page_size}`, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${store.state.auth.accessToken}`
                    // Authorization: `Bearer ${ localStorage.getItem('䫀笢') }`
            }
        });
    },



    // updateMuncipalOrder(order) {
    //     return API.apiClient.put(`/api/Order`, order);
    // },

};